import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import NewTA from './NewTA'
import DeleteTA from './DeleteTA'
import ChangeGroupTA from './ChangeGroupTA'

export default function TAsMenu({ isHA }) {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const career = useSelector((state) => state.career)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}
		setOpen(false)
	}

	const [newTAOpen, setNewTAOpen] = useState(false)
	const handleNewTAOpen = () => {
		setNewTAOpen(true)
	}
	const handleNewTAClose = () => {
		setNewTAOpen(false)
	}

	const [deleteTAOpen, setDeleteTAOpen] = useState(false)
	const handleDeleteTAOpen = () => {
		setDeleteTAOpen(true)
	}
	const handleDeleteTAClose = () => {
		setDeleteTAOpen(false)
	}

	const [changeGroupOpen, setChangeGroupOpen] = useState(false)
	const handleChangeGroupOpen = () => {
		setChangeGroupOpen(true)
	}
	const handleChangeGroupClose = () => {
		setChangeGroupOpen(false)
	}

	return (
		<>
			<NewTA
				open={newTAOpen}
				handleClose={handleNewTAClose}
				career={career}
			/>

			<DeleteTA
				open={deleteTAOpen}
				handleClose={handleDeleteTAClose}
			/>

			<ChangeGroupTA
				open={changeGroupOpen}
				handleClose={handleChangeGroupClose}
			/>

			{isHA ? (
				<Button
					variant='contained'
					color='success'
					onClick={handleChangeGroupOpen}
				>
					Cambio de grupo TA
				</Button>
			) : (
				<>
					<ButtonGroup
						disabled={!career}
						variant='contained'
						ref={anchorRef}
						aria-label='split button'
					>
						<Button>TA's</Button>
						<Button
							size='small'
							aria-controls={open ? 'split-button-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-label='select merge strategy'
							aria-haspopup='menu'
							onClick={handleToggle}
						>
							<ArrowDropDownIcon />
						</Button>
					</ButtonGroup>
					<Popper
						sx={{
							zIndex: 1
						}}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											id='split-button-menu'
											autoFocusItem
										>
											<MenuItem onClick={handleNewTAOpen}>Nuevo</MenuItem>
											<MenuItem onClick={handleDeleteTAOpen}>Eliminar</MenuItem>
											<MenuItem onClick={handleChangeGroupOpen}>Cambio de grupo</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</>
			)}
		</>
	)
}
