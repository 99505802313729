import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setActualLesson } from '../../../redux/actions'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const LessonSelector = () => {
	const dispatch = useDispatch()
	const allLessons = useSelector((state) => state.allLessons)
	const actualLesson = useSelector((state) => state.actualLesson)
	const actualModule = useSelector((state) => state.actualModule)

	const moduleLessons =
		actualModule === 1 ? allLessons[0] : actualModule === 2 ? allLessons[1] : actualModule === 3 ? allLessons[2] : actualModule === 4 ? allLessons[3] : actualModule === 5 ? allLessons[4] : actualModule === 6 ? allLessons[5] : null

	const handleLessonChange = (e) => {
		dispatch(setActualLesson(e.target.value))
	}

	useEffect(() => {}, [actualLesson])

	return (
		<>
			<FormControl
				sx={{ mt: 0.25, minWidth: 100, ml: 1 }}
				size='small'
			>
				<InputLabel id='demo-select-small'>Clase</InputLabel>
				<Select
					labelId='demo-select-small'
					id='demo-select-small'
					value={actualLesson}
					label='Clase'
					onChange={handleLessonChange}
				>
					<MenuItem
						disabled
						value=''
					>
						<em>Selecciona una clase</em>
					</MenuItem>
					{moduleLessons?.map((lesson) => (
						<MenuItem
							key={lesson}
							value={lesson}
						>
							{lesson}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default LessonSelector
