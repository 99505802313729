import { setCareer } from '../../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
// import NewCohort from './NewCohort/NewCohort'
import StudentMenu from './StudentMenu/StudentMenu'
import TAsMenu from './TAsMenu/TAsMenu'
// import CohortMenu from './CohortMenu/CohortMenu'

const Menu = ({ toogleArchive, userCareer }) => {
	const dispatch = useDispatch()
	const career = useSelector((state) => state.career)

	const handleCareer = (e) => {
		dispatch(setCareer(e.target.value))
	}

	return (
		<>
			<Box
				sx={{
					// border: '1px solid gray',
					width: userCareer.includes('HA') ? 'auto' : '80vw',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-evenly',
					alignItems: 'center',
					height: 75,
					mt: 1
				}}
			>
				{userCareer === 'super admin' || userCareer === 'admin' ? (
					<>
						<Box
							sx={{
								// border: '4px solid gray',
								width: '20%'
							}}
						>
							<FormControl sx={{ minWidth: '10vw' }}>
								<InputLabel id='career'>Carrera</InputLabel>
								<Select
									labelId='career'
									id='career'
									value={career}
									label='Carrera'
									defaultValue='Full Stack'
									onChange={handleCareer}
								>
									<MenuItem value='Full Stack'>Full Stack</MenuItem>
									<MenuItem value='Data Science'>Data Science</MenuItem>
								</Select>
							</FormControl>
						</Box>

						<Box
							sx={{
								// border: '4px solid gray',
								width: '30%'
							}}
						/>

						<Box
							sx={{
								// border: '4px solid gray',
								display: 'flex',
								justifyContent: 'space-evenly',
								width: '40%'
							}}
						>
							{/* <CohortMenu toogleArchive={toogleArchive} /> */}
							{userCareer === 'admin' && <TAsMenu isHA={false} />}
							{userCareer === 'admin' && <StudentMenu isHA={false} />}
						</Box>
					</>
				) : userCareer.includes('HA') ? (
					<>
						<Box
							sx={{
								// border: '4px solid gray',
								display: 'flex',
								flexDirection: 'column',
								gap: 3
							}}
						>
							<TAsMenu isHA={true} />
							<StudentMenu isHA={true} />
						</Box>
					</>
				) : null}
			</Box>
		</>
	)
}

export default Menu
