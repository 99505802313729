import { createTheme } from '@mui/material/styles'

const theme = createTheme({
	status: {
		danger: '#e53e3e'
	},
	palette: {
		yellowPrimary: {
			main: '#ffff00'
		},
		yellowNeutral: {
			main: '#ffff56'
		},
		yellowSecondary: {
			main: '#ffff81'
		},
		greyPrimary: {
			main: '#565557'
		},
		greySecondary: {
			main: '#858585'
		},
		black: {
			main: '#333233'
		}
	},
	// configuro colores para los tooltips
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					backgroundColor: '#333233',
					// color de la letra gris muy clarito
					color: 'white',
					fontSize: '1rem',
					fontWeight: 'semibold'
				}
			}
		}
	}
})

export default theme
