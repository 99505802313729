import React from 'react'
import { useSelector } from 'react-redux'
import CollectionsBookmarkTwoToneIcon from '@mui/icons-material/CollectionsBookmarkTwoTone'
import { Box, Typography } from '@mui/material'

const LastLesson = () => {
	const lastLesson = useSelector((state) => state.lastLesson)

	return (
		lastLesson && (
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					height: 40,
					border: '1px solid gray',
					borderRadius: '5px',
					paddingLeft: '0.5rem',
					paddingRight: '0.5rem',
					bgcolor: '#f5f5f5',
					opacity: 0.8,
					boxShadow: 5
				}}
			>
				<CollectionsBookmarkTwoToneIcon sx={{ mr: 1 }} />
				<Typography
					variant='body2'
					component='div'
					sx={{ flexGrow: 1, fontWeight: 'bold' }}
				>
					Última clase registrada: {lastLesson.toUpperCase()}
				</Typography>
			</Box>
		)
	)
}

export default LastLesson
