import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Box, Typography, Button, Stack, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material'
import { getCohorts, deleteCohort, createCohort, getLessons } from '../../redux/actions'
import { useSnackbar } from 'notistack'
import Loading from '../commons/Loading'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import deleteIcon from '../../assets/delete_icon.png'
import Lessons from './Menu/CohortMenu/Lessons'

const Cohorts = ({ cohorts, cohortArchiveEnabled }) => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const career = useSelector((state) => state.career)
	const token = useSelector((state) => state.user.token)
	const user = useSelector((state) => state.user)
	const allLessons = useSelector((state)=> state.allLessons)
	const [backdrop, setBackdrop] = useState(false)
	const [open, setOpen] = useState(false)
	const [localCohort, setLocalCohort] = useState({})
	const [openLessonsModal, setOpenLessonsModal] = useState(false)
	const [disabledLessons, setDisabledLessons] = useState([])
	const [selectedCohort, setSelectedCohort] = useState(null)
	const handleOpen = () => setOpen(true)
	const handleClose = () => setOpen(false)
	const handleCloseLessonsForm = () => {
		dispatch(createCohort(career, selectedCohort, disabledLessons));
		setOpenLessonsModal(false);
		setDisabledLessons([])
	}
	useEffect(()=>{
		dispatch(getLessons({ career }))
	}, [career, dispatch])

	const lessonSchedule = allLessons.map((lessons, index) => ({
		module: 'M'+ (index + 1),
		lessons
	}))
	cohorts = cohorts
		.filter((cohort) => cohort.career === career)
		.sort((a, b) => {
			const aNum = a.title.split('#')[1]
			const bNum = b.title.split('#')[1]
			if (aNum > bNum) return 1
			if (aNum < bNum) return -1
			return 0
		})

	const handleDelete = (career, cohort, token) => {
		handleClose()
		const auxCareer = career
		career = career.split(' ')[0][0] + career.split(' ')[1][0]
		setBackdrop(true)
		dispatch(deleteCohort(career, cohort, token))
			.then(() => {
				enqueueSnackbar(`Cohorte ${auxCareer} | ${cohort} archivada con éxito`, {
					variant: 'success'
				})
			})
			.then(() => {
				setBackdrop(false)
				dispatch(getCohorts(token))
			})
			.catch((err) => {
				console.error(err)
				enqueueSnackbar(err, {
					variant: 'error'
				})
				setBackdrop(false)
			})
	}

	return (
		<>
			{backdrop && <Loading />}
			<Lessons
				lessonSchedule={lessonSchedule}
				open={openLessonsModal}
				handleClose={handleCloseLessonsForm}
				disabledLessons={disabledLessons}
				setDisabledLessons={setDisabledLessons}
			/>
			<Box
				sx={{
					// border: '5px solid black',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<Typography
					variant='h6'
					component='div'
					sx={{
						fontWeight: 'bold',
						color: 'black',
						borderRadius: '5px',
						mb: 2
					}}
				>
					{career ? `Cohortes en bootcamp de la carrera ${career}` : 'Selecciona una carrera para ver los cohortes en bootcamp'}
				</Typography>

				<Grid
					container
					justifyContent='space-evenly'
					sx={{ padding: 2, gap: 2 }}
				>
					{cohorts?.map((cohort) => (
						<Grid
							item
							xs={12}
							sm={4}
							md={3}
							lg={2}
							key={cohort.title}
							sx={{
								// border: '4px solid green',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								minWidth: '16vw'
							}}
						>
							<Card
								sx={{
									width: '100%',
									height: '10rem',
									display: 'flex',
									justifyContent: 'center',
									flexDirection: 'column',
									alignItems: 'center',
									boxShadow: 3,
									'&:hover': {
										boxShadow: 5
									}
								}}
							>
								<Stack
									sx={{
										// border: '4px solid green',
										display: 'flex',
										margin: 'auto',
										justifyContent: 'center'
									}}
								>
									{/* TITULO */}
									<Box sx={{ margin: 'auto' }}>
										<Typography
											variant='h6'
											component='div'
											sx={{
												fontWeight: 'bold',
												color: 'black',
												padding: '5px'
											}}
										>
											{cohort.title}
										</Typography>
									</Box>

									{/* STATS */}
									<Stack
										sx={{
											margin: 'auto',
											textAlign: 'center',
											fontSize: '14px'
										}}
									>
										<Box>{cohort.studentsCount} alumnos totales</Box>
										{/* <Box>
                      {cohort.inactiveStudentsCount} inactivos
                      {' | '}
                      {cohort.newMigratedCount} nuevos migrados
                    </Box> */}
									</Stack>

									{/* ICONOS */}
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											alignItems: 'center',
											marginTop: '1rem'
										}}
									>
										<IconButton
											sx={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
											onClick={() => {
												setOpenLessonsModal(true)
												setSelectedCohort(cohort.title)
											}}
										>
											<CloudSyncOutlinedIcon fontSize='large' />
										</IconButton>
										{cohortArchiveEnabled & (user.career === 'super admin') ? (
											<Button
												onClick={() => {
													handleOpen()
													setLocalCohort(cohort)
												}}
											>
												<img
													src={deleteIcon}
													alt='borrar'
													height='28rem'
												/>
											</Button>
										) : null}
									</Box>
								</Stack>
							</Card>
						</Grid>
					))}
				</Grid>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<Alert severity='warning'>
						<DialogTitle id='alert-dialog-title'>¡Atención!</DialogTitle>
						<DialogContent>
							<DialogContentText id='alert-dialog-description'>{`¿Estás seguro que deseas archivar la cohorte ${localCohort.career} | ${localCohort.title}?`}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={handleClose}
								sx={{
									backgroundColor: '#f44336',
									color: 'white',
									'&:hover': {
										backgroundColor: '#f44336',
										color: 'white',
										opacity: [0.9, 0.8, 0.7]
									},
									'&:disabled': {
										backgroundColor: '#f44336',
										color: 'white',
										opacity: [0.6, 0.5, 0.4]
									}
								}}
							>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									handleDelete(localCohort.career, localCohort.title, token)
								}}
								autoFocus
								sx={{
									backgroundColor: '#4caf50',
									color: 'white',
									'&:hover': {
										backgroundColor: '#4caf50',
										opacity: [0.9, 0.8, 0.7],
										color: 'white'
									},
									'&:disabled': {
										backgroundColor: '#4caf50',
										opacity: [0.6, 0.5, 0.4],
										color: 'white'
									}
								}}
							>
								Archivar
							</Button>
						</DialogActions>
					</Alert>
				</Dialog>
			</Box>
		</>
	)
}

export default Cohorts
