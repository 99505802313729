import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ModalGroupChange from './GroupChange/ModalGroupChange'
import ModalMigrations from './Migrations/ModalMigrations'
import ModalDeleteStudent from './DeleteStudent/ModalDeleteStudent'
import ModalNewStudent from './NewStudent/ModalNewStudent'

export default function StudentMenu({ isHA }) {
	const [open, setOpen] = useState(false)
	const anchorRef = useRef(null)
	const user = useSelector((state) => state.user)
	const cohorts = useSelector((state) => state.cohorts)
	const career = useSelector((state) => state.career)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	const [groupChangeOpen, setGroupChangeOpen] = useState(false)
	const handleGroupChangeOpen = () => {
		setGroupChangeOpen(true)
	}
	const handleGroupChangeClose = () => {
		setGroupChangeOpen(false)
	}

	const [migrationsOpen, setMigrationsOpen] = useState(false)
	const handleMigrationsOpen = () => {
		setMigrationsOpen(true)
	}
	const handleMigrationsClose = () => {
		setMigrationsOpen(false)
	}

	const [studentDeleteOpen, setStudentDeleteOpen] = useState(false)
	const handleDeleteStudentOpen = () => {
		setStudentDeleteOpen(true)
	}
	const handleDeleteStudentClose = () => {
		setStudentDeleteOpen(false)
	}

	const [newStudentOpen, setNewStudentOpen] = useState(false)
	const handleNewStudentOpen = () => {
		setNewStudentOpen(true)
	}
	const handleNewStudentClose = () => {
		setNewStudentOpen(false)
	}

	return (
		<>
			<ModalGroupChange
				open={groupChangeOpen}
				handleClose={handleGroupChangeClose}
				career={career}
			/>
			<ModalMigrations
				open={migrationsOpen}
				handleClose={handleMigrationsClose}
				career={career}
				user={user}
				cohorts={cohorts}
			/>
			<ModalDeleteStudent
				open={studentDeleteOpen}
				handleClose={handleDeleteStudentClose}
				career={career}
			/>
			<ModalNewStudent
				open={newStudentOpen}
				handleClose={handleNewStudentClose}
				career={career}
			/>

			{isHA ? (
				<Button
					variant='contained'
					color='success'
					onClick={handleGroupChangeOpen}
				>
					Cambio de grupo alumno
				</Button>
			) : (
				<>
					<ButtonGroup
						disabled={!career}
						variant='contained'
						ref={anchorRef}
						aria-label='split button'
					>
						<Button>Alumnos</Button>
						<Button
							size='small'
							aria-controls={open ? 'split-button-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-label='select merge strategy'
							aria-haspopup='menu'
							onClick={handleToggle}
						>
							<ArrowDropDownIcon />
						</Button>
					</ButtonGroup>

					<Popper
						sx={{
							zIndex: 1
						}}
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList
											id='split-button-menu'
											autoFocusItem
										>
											<MenuItem onClick={handleNewStudentOpen}>Nuevo</MenuItem>
											<MenuItem onClick={handleGroupChangeOpen}>Cambio de grupo</MenuItem>
											<MenuItem onClick={handleMigrationsOpen}>Migraciones</MenuItem>
											<MenuItem onClick={handleDeleteStudentOpen}>Baja/Suspensión</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
				</>
			)}
		</>
	)
}
