import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import MuiToggleButton from '@mui/material/ToggleButton'

const AttendanceToogle = ({ value, handleAttendanceChange, github }) => {
	const actualLesson = useSelector((state) => state.actualLesson)
	const actualLessonAttendance = useSelector((state) => state.actualLessonAttendance)
	const nextLesson = useSelector((state) => state.nextLesson)
	const lastLesson = useSelector((state) => state.lastLesson)
	const allLessons = useSelector((state) => state.allLessons)
	const students = useSelector((state) => state.students)

	const allLessonsJoined = allLessons.flat()

	const isPreviousLesson = allLessonsJoined?.indexOf(actualLesson) <= allLessonsJoined?.indexOf(lastLesson)

	const isNextLesson = isPreviousLesson ? true : allLessonsJoined?.indexOf(actualLesson) === allLessonsJoined?.indexOf(nextLesson) ? true : false

	const [isCanceledLesson, setIsCanceledLesson] = useState(false)

	const inactiveStudents = students.filter((student) => student.status === 'Inactivo')

	const colors = {
		present: ['#11734b', '#d4edbc'],
		absent: ['#b10202', '#ffcfc9']
	}

	const ToggleButton = styled(MuiToggleButton)({
		'&.Mui-selected, &.Mui-selected:hover': {
			fontWeight: 'bold',
			color: value && colors[value][0],
			backgroundColor: value && colors[value][1]
		}
	})

	const children = [
		<ToggleButton
			value='present'
			key='present'
			aria-label='Presente'
			disabled={value === 'present'}
		>
			<CheckOutlinedIcon />
		</ToggleButton>,
		<ToggleButton
			value='absent'
			key='absent'
			aria-label='Ausente'
			disabled={value === 'absent'}
		>
			<CloseOutlinedIcon />
		</ToggleButton>
	]

	const control = {
		name: `attendance-${github}`,
		value,
		onChange: (e, newValue) => {
			const selectedChild = children.find((child) => child.props?.value === newValue)
			const selectedValue = selectedChild.props?.value
			handleAttendanceChange(github, selectedValue)
		},
		exclusive: true
	}

	useEffect(() => {
		setIsCanceledLesson(Object.values(actualLessonAttendance).includes('X'))
	}, [actualLesson, actualLessonAttendance, lastLesson])

	return (
		<Stack
			spacing={2}
			alignItems='center'
		>
			{value === null && actualLesson.length && isCanceledLesson ? (
				<Tooltip
					title='No corresponde pasar asistencia en esta clase.'
					placement='top'
				>
					<ToggleButtonGroup
						size='small'
						{...control}
						aria-label='Small sizes'
						disabled
					>
						{children}
					</ToggleButtonGroup>
				</Tooltip>
			) : value === null && isPreviousLesson && actualLesson.length && !isCanceledLesson ? (
				<Tooltip
					title='Este alumno no registró asistencia en la clase seleccionada. Puede ser porque no pertenecía al grupo en ese momento.'
					placement='top'
				>
					<ToggleButtonGroup
						size='small'
						{...control}
						aria-label='Small sizes'
						disabled
					>
						{children}
					</ToggleButtonGroup>
				</Tooltip>
			) : actualLesson.length && !inactiveStudents.length && !isNextLesson ? (
				<Tooltip
					title={`No estás en la clase correspondiente. Debes pasar asistencia en ${nextLesson}`}
					placement='top'
				>
					<ToggleButtonGroup
						size='small'
						{...control}
						aria-label='Small sizes'
						disabled
					>
						{children}
					</ToggleButtonGroup>
				</Tooltip>
			) : actualLesson.length && !inactiveStudents.length && isNextLesson ? (
				<ToggleButtonGroup
					size='small'
					{...control}
					aria-label='Small sizes'
				>
					{children}
				</ToggleButtonGroup>
			) : !actualLesson.length ? (
				<Tooltip
					title='Selecciona una clase para poder marcar la asistencia'
					placement='top'
				>
					<ToggleButtonGroup
						size='small'
						aria-label='Small sizes'
						disabled
					>
						{children}
					</ToggleButtonGroup>
				</Tooltip>
			) : inactiveStudents.length ? (
				<Tooltip
					title='Completa la baja de los alumnos para poder marcar la asistencia'
					placement='top'
				>
					<ToggleButtonGroup
						size='small'
						{...control}
						aria-label='Small sizes'
						disabled
					>
						{children}
					</ToggleButtonGroup>
				</Tooltip>
			) : null}
		</Stack>
	)
}

export default AttendanceToogle
