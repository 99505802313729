import { useSelector } from 'react-redux'
import { Button } from '@mui/material'

const AttendanceSubmitButton = ({ postAttendance, attendance }) => {
	const allLessons = useSelector((state) => state.allLessons)
	const nextLesson = useSelector((state) => state.nextLesson)
	const actualLesson = useSelector((state) => state.actualLesson)
	const lastLesson = useSelector((state) => state.lastLesson)
	const actualLessonAttendance = useSelector((state) => state.actualLessonAttendance)

	const allLessonsJoined = allLessons.flat()

	//UPGRADE TO NOT ENABLED CLASSES
	const isCanceledLesson = Object.values(actualLessonAttendance).includes('X')

	const isNextLesson = allLessonsJoined?.indexOf(actualLesson) === allLessonsJoined?.indexOf(nextLesson) ? true : false

	const isPreviousLesson = lastLesson.length ? allLessonsJoined?.indexOf(actualLesson) <= allLessonsJoined?.indexOf(lastLesson) : false

	const attendances = Object.values(attendance)

	const isAttendanceEmpty = attendances.some((attendance) => attendance === null)

	const enabled = isPreviousLesson ? false : !actualLesson.length || isCanceledLesson || !isNextLesson || isAttendanceEmpty ? true : false

	return (
		<>
			<Button
				variant='contained'
				size='small'
				sx={{
					height: 40,
					marginRight: '2rem',
					fontWeight: 'bold',
					paddingTop: '0.25rem',
					paddingBottom: '0.25rem',
					paddingLeft: '0.5rem',
					paddingRight: '0.5rem',
					backgroundColor: 'purple',
					border: '2px solid #5B2C6F',
					'&:hover': {
						color: 'yellow',
						backgroundColor: 'purple',
						border: '2px solid grey'
					}
				}}
				disabled={enabled}
				onClick={() => postAttendance(false)}
			>
				Enviar asistencias
			</Button>
		</>
	)
}

export default AttendanceSubmitButton
