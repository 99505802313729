import React from 'react'
import LastLesson from './LastLesson'
import LessonSelector from './LessonSelector'
import ModuleSelector from './ModuleSelector'
import { Box, Button } from '@mui/material'
import AttendanceSubmitButton from './AttendanceSubmitButton'
import { NavLink } from 'react-router-dom'

const Menu = ({ postAttendance, attendance, setAttendance, disabled }) => {
	return (
		<>
			<Box
				sx={{
					// border: '2px solid green',
					margin: 'auto',
					marginTop: '2vh',
					maxWidth: '95vw',
					padding: 0.7,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					height: '3rem'
				}}
			>
				<Box>
					<LastLesson />
				</Box>
				<Box>
					<ModuleSelector setAttendance={setAttendance} />
					<LessonSelector />
				</Box>
				<Box sx={{ display: 'flex' }}>
					<AttendanceSubmitButton
						postAttendance={postAttendance}
						attendance={attendance}
					/>
					<NavLink
						to={disabled ? null : '/evaluation'}
						style={disabled ? { pointerEvents: 'none', textDecoration: 'none' } : { textDecoration: 'none' }}
					>
						<Button
							variant='contained'
							size='small'
							sx={{
								height: 40,
								fontWeight: 'bold',
								paddingTop: '0.25rem',
								paddingBottom: '0.25rem',
								paddingLeft: '0.5rem',
								paddingRight: '0.5rem',
								backgroundColor: 'purple',
								border: '2px solid #5B2C6F',
								'&:hover': {
									color: 'yellow',
									backgroundColor: 'purple',
									border: '2px solid grey'
								}
							}}
							disabled={disabled}
						>
							Evaluación Final
						</Button>
					</NavLink>
				</Box>
			</Box>
		</>
	)
}

export default Menu
