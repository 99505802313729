import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { updateStudentMigration, studentDelete } from '../../redux/actions'
import StudentInfoView from './StudentInfo.view'

const StudentInfo = ({ student, setBackdrop, backdrop }) => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const user = useSelector((state) => state.user)

	const [open, setOpen] = useState(false)
	const [slack, setSlack] = useState(false)
	const [meet, setMeet] = useState(false)

	const handleSlack = () => {
		setSlack(!slack)
	}

	const handleMeet = () => {
		setMeet(!meet)
	}

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleMig = (student) => {
		setBackdrop(true)
		const prop = 'Nuevo Migrado'
		const value = 'No'
		dispatch(updateStudentMigration(user, student.github, prop, value))
			.then((res) => {
				setBackdrop(false)
				enqueueSnackbar(`El estudiante ${student.name} ha sido actualizado`, {
					variant: 'success'
				})
				setOpen(false)
			})
			.catch((err) => {
				setBackdrop(false)
				enqueueSnackbar(err.message, {
					variant: 'error'
				})
			})
	}

	const handleDelete = (student) => {
		setBackdrop(true)
		dispatch(studentDelete(user, student.github))
			.then((res) => {
				setBackdrop(false)
				enqueueSnackbar(res.message, {
					variant: 'success'
				})
				setOpen(false)
			})
			.catch((err) => {
				setBackdrop(false)
				enqueueSnackbar(err.message, {
					variant: 'error'
				})
			})
	}

	return (
		<>
			<StudentInfoView
				student={student}
				open={open}
				handleOpen={handleOpen}
				handleClose={handleClose}
				handleMig={handleMig}
				handleDelete={handleDelete}
				slack={slack}
				handleSlack={handleSlack}
				meet={meet}
				handleMeet={handleMeet}
				backdrop={backdrop}
			/>
		</>
	)
}

export default StudentInfo
