import React, { useState } from 'react'
import { Modal, Box, Typography, Button, Fade } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { postEvaluations } from '../../redux/actions'
import Loading from '../commons/Loading'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4
}

const ConfirmModal = ({ open, setOpen, evaluation, setEvaluation }) => {
	const dispatch = useDispatch()

	const navigate = useNavigate()

	const { enqueueSnackbar } = useSnackbar()

	const [backdrop, setBackdrop] = useState(false)

	const user = useSelector((state) => state.user)

	const handleClose = () => {
		setOpen(false)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setBackdrop(true)
		dispatch(postEvaluations(evaluation, user))
			.then((res) => {
				setBackdrop(false)
				setOpen(false)
				enqueueSnackbar('¡Asistencia enviada con éxito!', {
					variant: 'success'
				})
				setEvaluation({})
				navigate('/home')
			})
			.catch((err) => {
				enqueueSnackbar(`¡Error al enviar la asistencia! | ${err.message}`, {
					variant: 'error'
				})
				setBackdrop(false)
				setOpen(false)
			})
	}

	return (
		<>
			{backdrop ? (
				<Loading />
			) : (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='transition-modal-title'
					aria-describedby='transition-modal-description'
				>
					<Fade in={open}>
						<Box sx={style}>
							<Typography
								id='transition-modal-title'
								variant='h6'
								component='h2'
								sx={{
									textAlign: 'center'
								}}
							>
								¿Estás seguro de que quieres enviar la evaluación final?
							</Typography>
							<Typography
								id='transition-modal-description'
								sx={{
									marginTop: 5,
									marginBottom: 5,
									textAlign: 'center'
								}}
							>
								Una vez enviada no se podrá modificar.
							</Typography>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-around',
									alignItems: 'center',
									marginTop: '1vh'
								}}
							>
								<Button
									variant='contained'
									onClick={handleClose}
									sx={{
										backgroundColor: 'red',
										color: 'white',
										'&:hover': {
											backgroundColor: 'red',
											color: 'white',
											opacity: [0.9, 0.8, 0.7]
										}
									}}
								>
									Cancelar
								</Button>
								<Button
									variant='contained'
									onClick={handleSubmit}
									sx={{
										backgroundColor: 'green',
										color: 'white',
										'&:hover': {
											backgroundColor: 'green',
											color: 'white',
											opacity: [0.9, 0.8, 0.7]
										}
									}}
								>
									Confirmar
								</Button>
							</Box>
						</Box>
					</Fade>
				</Modal>
			)}
		</>
	)
}

export default ConfirmModal
