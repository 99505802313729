import { useSelector, useDispatch } from 'react-redux'
import { setActualModule, setActualLesson } from '../../../redux/actions'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

const ModuleSelector = ({ setAttendance }) => {
	const allLessons = useSelector((state) => state.allLessons)
	let count = 0
	const modules = [...new Set(allLessons.map(() => ++count))]
	const dispatch = useDispatch()
	const actualModule = useSelector((state) => state.actualModule)

	const handleModuleChange = (e) => {
		dispatch(setActualModule(e.target.value))
		dispatch(setActualLesson(''))
		setAttendance({})
	}

	return (
		<>
			<FormControl
				sx={{ mt: 0.25, minWidth: 100, mr: 1 }}
				size='small'
			>
				<InputLabel id='demo-select-small'>Módulo</InputLabel>
				<Select
					labelId='demo-select-small'
					id='demo-select-small'
					value={actualModule || ''}
					label='Módulo'
					onChange={handleModuleChange}
				>
					<MenuItem
						disabled
						value=''
					>
						<em>Selecciona un modulo</em>
					</MenuItem>
					{modules.map((module) => (
						<MenuItem
							key={module}
							value={module}
						>
							{module}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	)
}

export default ModuleSelector
