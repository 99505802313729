import { Box, Button, Card, CardContent, CardHeader, Divider, Typography, ThemeProvider } from '@mui/material'
import theme from '../../theme/theme'
import { Link } from 'react-router-dom'

const NotFound = () => {
	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%'
				}}
			>
				<Card
					sx={{
						backgroundColor: 'background.default',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%'
					}}
				>
					<CardHeader title='404' />
					<Divider />
					<CardContent>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%'
							}}
						>
							<Typography
								color='textPrimary'
								variant='h3'
							>
								La página que buscas no existe
							</Typography>
							<Box>
								<img
									src='https://assets.soyhenry.com/henry-landing/assets/emojis/CrashedRocket.png'
									alt='404 not found'
									style={{ width: '100%', height: '100%' }}
								/>
							</Box>
							<Box
								sx={{
									py: 2
								}}
							>
								<Button
									component={Link}
									to='/'
									variant='contained'
									sx={{
										color: 'greyPrimary.main',
										backgroundColor: 'yellowPrimary.main',
										opacity: 0.8,
										textDecoration: 'none',
										'&:hover': {
											backgroundColor: 'yellowSecondary.main'
										}
									}}
								>
									Volver a la página principal
								</Button>
							</Box>
						</Box>
					</CardContent>
					<Divider />
				</Card>
			</Box>
		</ThemeProvider>
	)
}

export default NotFound
