import axios from 'axios'
import { ApiTaURL, ApiAdminURL } from '../config'
import { createTeachingAssistant, deleteInactiveStudent, getCareerLessons, getSchoolCohorts, getSchoolStudents, getStudentsLessonAttendance, setSchoolAttendances, syncCohort, updateStudentOnboardingStatus, updateStudentsGroup } from '../service/api.school.service'
import { actionTypes } from './actionTypes'


const manageError = (error) => {
  console.error(error)
  if (!error.response) throw Error('No hay conexión con el servidor')
  if (error.response.status === 429) throw Error('Demasiadas peticiones')
  if (error.response.request.status === 401 || error.response.request.status === 403) {
    window.localStorage.removeItem('persist:root')
    window.location.assign('/')
  }
  if (error.response) throw Error(error.response.data.message)
  throw error
}

// ! GENERIC ACTIONS
// ? Auth
export const login = (user) => {
  return (dispatch) => {
    try {
      dispatch({
        type: actionTypes.SET_USER,
        payload: user
      })
      return user;
    } catch (error) {
      manageError(error)
    }
  }
}

export const logoutUser = () => {
  return (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOGOUT })
      window.localStorage.removeItem('persist:root')
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Estados de redux
export const setCareer = (career) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CAREER,
      payload: career
    })
  }
}

export const clearStudents = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_STUDENTS,
      payload: []
    })
  }
}

export const setActualModule = (module) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ACTUAL_MODULE,
      payload: module
    })
  }
}

export const setActualLesson = (lesson) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ACTUAL_LESSON,
      payload: lesson
    })
  }
}

// ! ----- ADMIN ACTIONS ----- ! //
// ? Cohorts | Create Cohort
export const createCohort = (career, cohort, disabledLessons, token) => {
  return async (dispatch) => {
    try {
      const body = {
        career,
        cohort,
        disabledLessons
      }

      const response = await syncCohort(body);
 
      dispatch({
        type: actionTypes.CREATE_COHORT,
        payload: response.data
      })

      const responseCohorts = await getSchoolCohorts()
      dispatch({
        type: actionTypes.GET_COHORTS,
        payload: responseCohorts.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Cohorts | Get Cohorts
export const getCohorts = (token) => {
  return async (dispatch) => {
    try {
      const response = await getSchoolCohorts()
      dispatch({
        type: actionTypes.GET_COHORTS,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Cohorts | Get Cohorts HA
export const getCohortsHA = (token, career, cohort) => {
  return async (dispatch) => {
    try {
      career = career.split('-')[1]
      cohort = cohort.replace('#', '%23')
      const response = await axios.get(`${ApiAdminURL}/cohorts?career=${career}&cohort=${cohort}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.GET_COHORT_GROUPS,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Cohorts | Delete Cohort
export const deleteCohort = (career, cohort, token) => {
  return async (dispatch) => {
    try {
      cohort = cohort.replace('#', '%23')
      const response = await axios.delete(`${ApiAdminURL}/cohorts/${career}/${cohort}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.DELETE_COHORT,
        payload: response.data
      })
      const responseCohorts = await axios.get(`${ApiAdminURL}/cohorts`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.GET_COHORTS,
        payload: responseCohorts.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Lessons | Get Lessons
export const getLessonsAdmin = (token, career) => {
  return async (dispatch) => {
    try {
      career = career === 'Full Stack' ? 'FS' : career === 'Data Science' ? 'DS' : null
      const response = getCareerLessons(career);
      const lessons = response.data.map(({ name })=> name);
      dispatch({
        type: actionTypes.GET_LESSONS_ADMIN,
        payload: lessons
      })
      return lessons;
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | New Student
export const newStudent = (user, career, cohort, newStudents) => {
  return async (dispatch) => {
    try {
      const token = user.token
      const body = { career, cohort, newStudents }
      const response = await axios.post(`${ApiAdminURL}/students`, body, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const students = response.data
      dispatch({
        type: actionTypes.NEW_STUDENT,
        payload: students
      })
      return students
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Migrations
export const migrations = (data, user) => {
  return async (dispatch) => {
    try {
      const token = user.token
      const body = {
        students: data.students,
        career: data.career,
        origin: data.origin,
        target: data.target
      }
      const response = await axios.put(`${ApiAdminURL}/students`, body, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const studentsMigrated = response.data
      dispatch({
        type: actionTypes.MIGRATIONS,
        payload: studentsMigrated
      })
      return studentsMigrated
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Change Group
export const updateStudentGroup = (user, students, prop, group, career, cohort) => {
  return async (dispatch) => {
    try {
      const body = { students, career, cohort, group };
      const response = await updateStudentsGroup(body);
      dispatch({
        type: actionTypes.UPDATE_STUDENT_GROUP,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Change Status
export const updateStudentStatus = (user, input, prop, value, careerSelected, cohort) => {
  return async (dispatch) => {
    try {
      const { token } = user
      const students = input
      const body = { prop, value, students }
      cohort = cohort.replace('#', '%23')
      const response = await axios.patch(`${ApiAdminURL}/students/${careerSelected}/${cohort}`, body, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.UPDATE_STUDENT_STATUS,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? TA's | Create TA
export const createTA = (token, email, career) => {
  return async (dispatch) => {
    try {
      career = career === 'Full Stack' ? 'FS' : career === 'Data Science' ? 'DS' : null
      const data = { email, career }
      const response = await createTeachingAssistant(data);
      dispatch({
        type: actionTypes.CREATE_TA,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? TA's | Delete TA
export const deleteTA = (token, email) => {
  return async (dispatch) => {
    try {
      const response = await axios.delete(`${ApiAdminURL}/users/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.DELETE_TA,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? TA's | Change Group TA
export const updateGroupTA = (token, email, group) => {
  return async (dispatch) => {
    try {
      const data = { username: email, newGroup: group }
      const response = await axios.put(`${ApiAdminURL}/users`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      dispatch({
        type: actionTypes.UPDATE_GROUP_TA,
        payload: response.data
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ! ----- TA's ACTIONS ----- ! //
// ? Students | Get Students
export const getStudents = (user, moduleNumber) => {
  return async (dispatch) => {
    try {
      const { career, group } = user
      const cohort = user.cohort.replace('#', '%23')

      const response = await getSchoolStudents(career, cohort, group, moduleNumber);
      dispatch({
        type: actionTypes.SET_STUDENTS,
        payload: response.data.students
      })
      dispatch({
        type: actionTypes.SET_LAST_LESSON,
        payload: response.data.lastLesson
      })
      dispatch({
        type: actionTypes.SET_NEXT_LESSON,
        payload: response.data.nextLesson
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Set Attendances
export const setAttendances = (career, cohort, group, lesson, data, overwrite, token) => {
  return async (dispatch) => {
    try {
      const response = await setSchoolAttendances(career, cohort, group, lesson, data, overwrite, token)
      const attendance = response.data
      dispatch({
        type: actionTypes.SET_ATTENDANCE,
        payload: attendance
      })
      return attendance
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Complete Migration
export const updateStudentMigration = (user, github, prop, value) => {
  return async (dispatch) => {
    try {
      const { career, group } = user
      const cohort = user.cohort.replace('#', '%23')
      await updateStudentOnboardingStatus(github, career, cohort)

      const { data } = await getSchoolStudents(career, cohort, group);
      dispatch({
        type: actionTypes.SET_STUDENTS,
        payload: data.students
      })
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Delete Student
export const studentDelete = (user, studentGithub) => {
  return async (dispatch) => {
    try {
      const { career } = user
      const cohort = user.cohort.replace('#', '%23')
      const response = await deleteInactiveStudent(studentGithub, career, cohort)
      dispatch({
        type: actionTypes.DELETE_STUDENT,
        payload: studentGithub
      })
      return response.data
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Students | Post Evaluations
export const postEvaluations = (data, user) => {
  return async (dispatch) => {
    try {
      const { token, career } = user
      const cohort = user.cohort.replace('#', '%23')
      const response = await axios.post(`${ApiTaURL}/students/evaluation/${career}/${cohort}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const evaluations = response.data
      dispatch({
        type: actionTypes.POST_EVALUATIONS,
        payload: evaluations
      })
      return evaluations
    } catch (error) {
      manageError(error)
    }
  }
}

const formatLessonsModules = (data, career) => {
  let lessonsModules

  if (career === 'FS' || career === 'Full Stack') {
    lessonsModules = [data.filter(lesson => lesson.module === 'M1').map(({ name })=> name), data.filter(lesson => lesson.module === 'M2').map(({ name })=> name), data.filter(lesson => lesson.module === 'M3').map(({ name })=> name), data.filter(lesson => lesson.module === 'M4').map(({ name })=> name)]
  } else {
    lessonsModules = [data.filter(lesson => lesson.module === 'M1').map(({ name })=> name), data.filter(lesson => lesson.module === 'M2').map(({ name })=> name), data.filter(lesson => lesson.module === 'M3').map(({ name })=> name), data.filter(lesson => lesson.module === 'M4').map(({ name })=> name), data.filter(lesson => lesson.module === 'M5').map(({ name })=> name), data.filter(lesson => lesson.module === 'M6').map(({ name })=> name)]
  }
  return lessonsModules
}
// ? Lessons | Get Lessons
export const getLessons = (user) => {
  return async (dispatch) => {
    try {
      const response = await getCareerLessons(user.career);

      const lessonsModules = formatLessonsModules(response.data, user.career)

      dispatch({
        type: actionTypes.SET_ALL_LESSONS,
        payload: lessonsModules
      })
      return response.data.map(({ name })=>name)
    } catch (error) {
      manageError(error)
    }
  }
}

// ? Lessons | Get Lesson Attendances
export const getLessonAttendances = (user, lesson) => {
  return async (dispatch) => {
    try {
      const cohort = user.cohort.replace('#', '%23')
      const response = await getStudentsLessonAttendance(user.career,cohort, user.group, lesson)
      const attendanceFormatted = response.data.attendance.reduce((obj, item) => {
        obj[item.github] = item.asistencia
        return obj
      }, {})
      dispatch({
        type: actionTypes.GET_LESSON_ATTENDANCE,
        payload: attendanceFormatted
      })
      return attendanceFormatted
    } catch (error) {
      manageError(error)
    }
  }
}
