import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect, useCallback } from 'react'
import { getCohorts, getCohortsHA, getLessonsAdmin } from '../../redux/actions'
import { Grid, Card, Stack } from '@mui/material'
import CohortInfo from './CohortInfo.jsx'
import CohortGroupsInfo from './CohortGroupsInfo.jsx'
import Cohorts from './Cohorts.jsx'
import Menu from './Menu/Menu'
import Loading from '../commons/Loading'

const Dashboard = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector((state) => state.user)
	const cohorts = useSelector((state) => state.cohorts)
	const career = useSelector((state) => state.career)
	const groups = useSelector((state) => state.actualCohort.groups)
	const [cohortArchiveEnabled, setCohortArchiveEnabled] = useState(false)
	const [backdrop, setBackdrop] = useState(false)

	const toogleArchive = () => {
		setCohortArchiveEnabled(!cohortArchiveEnabled)
	}

	useEffect(() => {
		if (user.career !== 'admin' && user.career !== 'super admin' && !user.career.includes('HA')) navigate('/home')
	}, [user.career, navigate])

	const getCohortsCallback = useCallback(() => {
		setBackdrop(true)
		if (user.career.includes('HA')) {
			dispatch(getCohortsHA(user.token, user.career, user.cohort)).then(() => setBackdrop(false))
		} else {
			dispatch(getCohorts(user.token)).then(() => setBackdrop(false))
		}
	}, [dispatch, setBackdrop, user.career, user.cohort, user.token])

	useEffect(() => {
		getCohortsCallback()
		dispatch(getLessonsAdmin(user.token, career))
	}, [dispatch, user, career, getCohortsCallback])

	return (
		<>
			{backdrop && <Loading />}

			{!user.career?.includes('HA') ? (
				<Stack
					container='true'
					spacing={2}
					sx={{
						// border: '5px solid red',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<Menu
						toogleArchive={toogleArchive}
						userCareer={user.career}
					/>

					{/* cohortes */}
					<Grid
						item
						xs={12}
					>
						<Card
							sx={{
								// border: '5px solid blue',
								width: '98vw',
								height: 'auto',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								boxShadow: 3,
								margin: '1vw',
								// backgroundColor: 'white',
								paddingTop: '2vh'
							}}
						>
							<Cohorts
								cohorts={cohorts}
								cohortArchiveEnabled={cohortArchiveEnabled}
							/>
						</Card>
					</Grid>
				</Stack>
			) : user.career.includes('HA') ? (
				<Stack
					container='true'
					sx={{
						// border: '5px solid red',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<CohortInfo />
					<CohortGroupsInfo groups={groups} />
				</Stack>
			) : null}
		</>
	)
}

export default Dashboard
