import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Store from './redux/store'
import './index.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { AuthProvider } from './service/Auth'
const { persistor, store } = Store
// persistor.purge()

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
      <AuthProvider authUrl={process.env.REACT_APP_AUTH_URL}>
        <BrowserRouter>
          <SnackbarProvider maxSnack={3} autoHideDuration={4000}>
            <App />
          </SnackbarProvider>
        </BrowserRouter>
      </ AuthProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>
)

reportWebVitals()
