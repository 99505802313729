import React from 'react';

import { Button,Typography } from '@mui/material';

import { Logout } from '@mui/icons-material';
import { useAuth } from '.';

const LoginButton = () => {
  const { logIn } = useAuth();

  return (
    <Button
    //   fullWidth={fullWidth}
      color="primary"
      variant="contained"
    //   className={className}
      onClick={() => logIn()}
    >
      Log In
    </Button>
  );
};

const LogoutButton = ({ className }) => {

  const { logOut } = useAuth();

  // TODO: change returnTo so it's always '/'
  return (
    <Button variant="contained" className={className} onClick={logOut}>
      <Logout size={20} />
      <Typography>Logout</Typography>
    </Button>
  );
};

const AuthButton = (props) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <span>Auth loading...</span>;
  }
  return isAuthenticated ? (
    <LogoutButton {...props} />
  ) : (
    <LoginButton {...props} />
  );
};

export { AuthButton, LoginButton, LogoutButton };
