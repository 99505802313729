import React from 'react'
import { Grid, Box, Typography } from '@mui/material'

const NotStudents = () => {
	return (
		<Grid
			container
			justifyContent='center'>
			<Grid
				item
				xs={12}
				md={6}
				lg={4}>
				<Box sx={{ height: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, boxShadow: 3, bgcolor: 'white', borderRadius: 4, m: 6 }}>
					<Typography
						variant='h5'
						component='div'
						sx={{ mb: 2 }}>
						Módulo finalizado
					</Typography>
					<Typography
						variant='body1'
						color='text.secondary'>
						No hay acciones a realizar en el módulo seleccionado ya que se encuentra finalizado. Continúe con el siguiente módulo y recuerde que si necesita realizar
						alguna modificación en las asistencias, puede contactar al staff de Henry.
					</Typography>
				</Box>
			</Grid>
		</Grid>
	)
}

export default NotStudents
