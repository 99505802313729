import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateStudentStatus } from '../../../../../redux/actions'
import { Modal, TextField, Button, Typography, Box, Divider, Alert, IconButton, Autocomplete } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import Loading from '../../../../commons/Loading'

const ModalDeleteStudent = ({ open, handleClose, career }) => {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()

	const user = useSelector((state) => state.user)
	const cohorts = useSelector((state) => state.cohorts)

	const [backdrop, setBackdrop] = useState(false)
	const [github, setGithub] = useState('')
	const [cohort, setCohort] = useState('')
	const [input, setInput] = useState([])

	const handleGithubsChange = (e) => {
		setGithub(e.target.value)
	}

	const handleInputChange = () => {
		const lines = github.split('\n')
		let count = 0
		for (const line of lines) {
			if (!input.includes(line) && line !== '') {
				setInput((input) => [...input, line.trim()])
				count++
			}
		}
		if (lines.length === 1 && lines[0] === '') {
			enqueueSnackbar('No se han ingresado estudiantes', {
				variant: 'error'
			})
		} else if (count) {
			setGithub('')
			enqueueSnackbar(`Se han agregado ${count} estudiantes`, {
				variant: 'success'
			})
		}
	}

	const clearInput = () => {
		setInput([])
	}

	const handleDelete = (github) => {
		setInput(input.filter((m) => m !== github))
	}

	const clearAll = () => {
		setGithub('')
		setCohort('')
		setInput([])
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		setBackdrop(true)
		const prop = 'Estado'
		const value = 'Inactivo'
		const careerSelected = career.split(' ')[0][0] + career.split(' ')[1][0]
		dispatch(updateStudentStatus(user, input, prop, value, careerSelected, cohort))
			.then((res) => {
				setBackdrop(false)
				enqueueSnackbar(res.message, {
					variant: res.message.includes('no encontrado') ? 'warning' : 'success'
				})
				clearAll()
			})
			.catch((err) => {
				setBackdrop(false)
				enqueueSnackbar(err.message, {
					variant: 'error'
				})
				clearAll()
			})
	}

	let id = 0

	const rows = input?.map((github) => {
		return {
			id: id++,
			github,
			delete: github
		}
	})

	const columns = [
		{ field: 'id', headerName: 'ID', flex: 0.4, hide: true },
		{ field: 'github', headerName: 'Github', flex: 0.4, minWidth: 200 },
		{
			field: 'delete',
			headerName: 'Eliminar',
			flex: 0.4,
			maxWidth: 100,
			renderCell: (params) => (
				<IconButton
					aria-label='delete'
					onClick={() => handleDelete(params.row.github)}
				>
					<DeleteIcon />
				</IconButton>
			)
		}
	]
	useEffect(() => {}, [input, dispatch, user, enqueueSnackbar])

	return (
		<>
			{backdrop ? (
				<Loading sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} />
			) : (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					BackdropProps={{ onClick: () => {} }}
				>
					<Box
						sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							width: '70vw',
							bgcolor: 'background.paper',
							border: '2px solid #000',
							boxShadow: 24,
							p: 4,
							display: 'flex',
							flexDirection: 'column',
							gap: 2,
							maxHeight: '90vh'
						}}
					>
						<Typography
							variant='h4'
							component='h2'
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '1rem'
							}}
						>
							Baja de alumnos
						</Typography>

						<Button
							variant='contained'
							color='error'
							onClick={() => {
								handleClose()
								clearAll()
							}}
							sx={{
								position: 'absolute',
								top: 0,
								right: 0,
								backgroundColor: '#f44336',
								color: '#fff',
								borderRadius: '0 0 0 5px'
							}}
						>
							X
						</Button>

						<Divider sx={{ width: '95%', marginBottom: '1%', marginTop: '1%' }} />

						<Alert severity='info'>Agrega los usuarios de Github de los estudiantes que deseas dar de baja. Recuerda que debe ser una lista separando cada linea con un salto de linea. No utilizar puntos ni comas.</Alert>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '1rem',
								margin: 1.5
							}}
						>
							<Autocomplete
								id='combo-box-demo'
								options={cohorts
									.filter((cohort) => cohort?.career === career)									}
								getOptionLabel={(cohort) => cohort?.title}
								isOptionEqualToValue={(option, value) => option?.title === value?.title}
								style={{ width: '45%' }}
								onChange={(e, value) => setCohort(value?.title)}
								renderInput={(params) => (
									<TextField
										{...params}
										label='Cohorte'
										variant='outlined'
									/>
								)}
							/>
						</Box>
						<Divider
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								gap: '1rem',
								width: '95%',
								marginBottom: 1.5
							}}
						/>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-evenly',
								alignItems: 'center',
								gap: '1rem'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									width: '45%',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								<TextField
									id='outlined-multiline-static'
									label='Githubs'
									multiline
									rows={window.innerHeight > 1300 ? (window.innerHeight / 100) * 1.75 : (window.innerHeight / 100) * 1}
									value={github}
									onChange={handleGithubsChange}
									sx={{
										width: '100%',
										m: 1,
										maxHeight: '100%'
									}}
								/>
								<Button
									variant='contained'
									onClick={handleInputChange}
									sx={{
										backgroundColor: '#4caf50',
										color: 'white',
										'&:hover': {
											backgroundColor: '#4caf50',
											opacity: [0.9, 0.8, 0.7],
											color: 'white'
										},
										'&:disabled': {
											backgroundColor: '#4caf50',
											opacity: [0.6, 0.5, 0.4],
											color: 'white'
										}
									}}
								>
									Agregar
								</Button>
							</Box>
							<Box
								sx={{
									display: 'flex',
									width: '45%',
									flexDirection: 'column',
									justifyContent: 'space-evenly',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								{!input?.length ? (
									<Alert severity='warning'>No se han agregado estudiantes</Alert>
								) : (
									<Box
										sx={{
											minHeight: '25vh',
											height: document.getElementById('outlined-multiline-static')?.clientHeight + 35,
											width: '100%',
											m: 2
										}}
									>
										<DataGrid
											rows={rows}
											columns={columns}
											pageSize={input?.length}
											rowsPerPageOptions={[input?.length]}
											disableSelectionOnClick
											hideFooter
										/>
									</Box>
								)}
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-evenly',
										alignItems: 'center',
										gap: '1rem'
									}}
								>
									<Button
										variant='contained'
										onClick={clearInput}
										sx={{
											backgroundColor: '#f44336',
											color: 'white',
											'&:hover': {
												backgroundColor: '#f44336',
												color: 'white',
												opacity: [0.9, 0.8, 0.7]
											},
											'&:disabled': {
												backgroundColor: '#f44336',
												color: 'white',
												opacity: [0.6, 0.5, 0.4]
											}
										}}
										disabled={!input?.length}
									>
										Borrar todo ({input?.length})
									</Button>

									<Button
										variant='contained'
										onClick={handleSubmit}
										sx={{
											backgroundColor: '#4caf50',
											color: 'white',
											'&:hover': {
												backgroundColor: '#4caf50',
												opacity: [0.9, 0.8, 0.7],
												color: 'white'
											},
											'&:disabled': {
												backgroundColor: '#4caf50',
												opacity: [0.6, 0.5, 0.4],
												color: 'white'
											}
										}}
										disabled={!input?.length || !cohort?.length}
									>
										Completar baja
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				</Modal>
			)}
		</>
	)
}

export default ModalDeleteStudent
