import React from 'react'
import { NavLink } from 'react-router-dom'

import { AppBar, Box, Toolbar, Typography, Avatar, IconButton, Tooltip, ThemeProvider } from '@mui/material'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import theme from '../../theme/theme'

const NavView = ({ user, career, location, logout }) => {
	return (
		<>
			{location === '/home' || location === '/evaluation' || location === '/dashboard' ? (
				<ThemeProvider theme={theme}>
					<Box sx={{ flexGrow: 1 }}>
						<AppBar
							position='static'
							color='yellowPrimary'
							sx={{
								padding: 1.2,
								height: '80px'
							}}
						>
							<Toolbar>
								<Avatar
									alt='Logo Henry'
									src='https://assets.soyhenry.com/logoOG.png'
									sx={{ width: 56, height: 56 }}
								/>

								<Typography
									variant='h6'
									component='div'
									sx={{ flexGrow: 1, textAlign: 'center' }}
								>
									{user.cohort !== 'admin' && !user.career?.includes('HA') ? `${user.career} - ${user.cohort} | Grupo ${user.group}` : user.cohort !== 'admin' && user.career?.includes('HA') ? 'Panel de HA' : 'Panel de Admin'}
								</Typography>

								{/* Boton para volver para atrás con icono de MUI, cuando la location sea /evaluation */}
								{location === '/evaluation' ? (
									<NavLink
										to='/home'
										style={{ textDecoration: 'none' }}
									>
										<Tooltip
											title='Volver'
											arrow
										>
											<IconButton
												size='large'
												sx={{ color: 'black' }}
												aria-label='Volver'
											>
												<ArrowBackIosIcon />
											</IconButton>
										</Tooltip>
									</NavLink>
								) : null}

								<Typography
									variant='h6'
									component='div'
									sx={{
										flexGrow: 1,
										textAlign: 'right',
										marginRight: 2,
										marginLeft: '60%'
									}}
								>
									{`${user.name}`}
								</Typography>
								<NavLink
									to='/'
									style={{ textDecoration: 'none' }}
								>
									<Tooltip
										title='Cerrar sesión'
										arrow
									>
										<IconButton
											size='large'
											sx={{ color: 'black' }}
											aria-label='Cerrar sesión'
											onClick={logout}
										>
											<LogoutOutlinedIcon />
										</IconButton>
									</Tooltip>
								</NavLink>
							</Toolbar>
						</AppBar>
					</Box>
				</ThemeProvider>
			) : null}
		</>
	)
}

export default NavView
