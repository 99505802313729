import { Routes, Route, useNavigate } from 'react-router-dom'
import Login from './components/Login/Login'
import Nav from './components/Nav/Nav'
import Home from './components/Home/Home'
import Evaluation from './components/Evaluation/Evaluation'
import Dashboard from './components/Dashboard/Dashboard'
import NotFound from './components/NotFound/NotFound'
import { useGetUserInfo } from './service/api.school.service'
import Loading from './components/commons/Loading'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { login } from './redux/actions'

const ADMIN_CAREERS = ['super admin', 'admin', 'HA-fs', 'HA-ds']

function App () {

  const { data, loading, isRefetching, isAuthenticated } = useGetUserInfo();
  const dispatch = useDispatch();
	const navigate = useNavigate()

  useEffect(()=>{
    if(data && isAuthenticated){
      dispatch(login(data))
    ADMIN_CAREERS.includes(data.career) ? navigate('/dashboard') : navigate('/home');
    }
  },[data, dispatch, isAuthenticated, navigate])

  if(loading || isRefetching) return <Loading />

  return (
    <div>
      <Nav />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/evaluation' element={<Evaluation />} />
        <Route path='/dashboard' element={<Dashboard />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default App
