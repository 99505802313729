// Based on https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import axios from 'axios';
import { useAuth } from "./Auth";

const { REACT_APP_BACKEND_URL: BACKEND_URL } = process.env;

function reloadIfNotLoggedIn(error) {
  if (error.error === 'login_required') {
    window.location.replace('/');
  } else {
    console.error(error);
  }
}

const fetchWithAuth = (
  accessToken,
  fetchOptions
) => {
  return axios({
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const callAPI = async (
  getAccessToken,
  fetchOptions
) => {
  try {
    const accessToken = await getAccessToken();
    const response = await fetchWithAuth(accessToken, fetchOptions);
    return response;
  } catch (error) {
    reloadIfNotLoggedIn(error);
    return error.response;
  }
};


export const useAPI = (
  fetchOptions,
  hookDeps = [],
  requiredDeps
) => {
  const { getAccessToken, isAuthenticated, isLoading: loadingAuth } = useAuth();
  const [state, setState] = useState({
    isRefetching: false,
    loading: true,
    error: null,
    isAuthenticated,
    data: null,
  });
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    if (isAuthenticated && !(requiredDeps && hookDeps.some((dep) => !dep))) {
      setState((state) => ({
        ...state,
        isRefetching: !state.loading,
      }));
      (async () => {
        try {
          const accessToken = await getAccessToken();
          const response = await fetchWithAuth(accessToken, fetchOptions);
          setState({
            ...state,
            data: await response.data,
            error: null,
            isAuthenticated,
            loading: false,
          });
        } catch (error) {
          reloadIfNotLoggedIn(error);
          setState({
            ...state,
            error,
            loading: false,
          });
        }
      })();
    } else {
      setState({
        isRefetching: false,
        loading: loadingAuth,
        error: null,
        isAuthenticated,
        data: null,
      });
    }
  }, [refreshIndex, loadingAuth, isAuthenticated, ...hookDeps]);

  return {
    ...state,
    isAuthenticated,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  };
};


export const useGetUserInfo = () =>
  useAPI({
    url: `${BACKEND_URL}/me`,
  });

//ADMIN
export const getCareerLessons = async (career) =>await axios.get(`${BACKEND_URL}/lessons?career=${career}`);

export const getSchoolCohorts = async () => await axios.get(`${BACKEND_URL}/cohorts`);

//TEACHING ASSISTANTS
export const getSchoolStudents = async (career, cohort, group, moduleNumber) => await axios.get(`${BACKEND_URL}/students?career=${career}&cohort=${cohort}&group=${group}&moduleNumber=${moduleNumber}`);

export const getStudentsLessonAttendance = async (career, cohort, group, lesson) => await axios.get(`${BACKEND_URL}/students/attendance?career=${career}&cohort=${cohort}&group=${group}&lesson=${lesson}`);

export const setSchoolAttendances = async (career, cohort, group, lesson, data, overwrite, token) => await axios.post(`${BACKEND_URL}/students/attendance?career=${career}&cohort=${cohort}&group=${group}&lesson=${lesson}&overwrite=${overwrite}`, data); 

export const updateStudentOnboardingStatus = async (github, career, cohort) => await axios.patch(`${BACKEND_URL}/students/${career}/${cohort}`, { github }); 

export const deleteInactiveStudent = async (github, career, cohort) => await axios.delete(`${BACKEND_URL}/students/${career}/${cohort}/${github}`); 

export const syncCohort = async (data) => await axios.post(`${BACKEND_URL}/cohorts/sync`, data); 

export const createTeachingAssistant = async (data) => await axios.post(`${BACKEND_URL}/teaching_assistants`, data); 

export const updateStudentsGroup = async (data) => await axios.patch(`${BACKEND_URL}/students/group`, data); 
