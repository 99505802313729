import React from 'react'
import { Box, Typography, Button, Modal, IconButton, Divider, Tooltip, Checkbox, FormControlLabel, Alert } from '@mui/material'
import { useSnackbar } from 'notistack'
import emailLogo from '../../assets/email-512.png'
import githubLogo from '../../assets/github-512.webp'
import mundoImg from '../../assets/mundo.jpg'
import slackLogo from '../../assets/slack.png'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone'
import Flags from '../commons/Flags'

const StudentInfoView = ({ student, open, handleClose, handleOpen, handleMig, handleDelete, slack, handleSlack, meet, handleMeet, backdrop }) => {
	const formatName = (name) => {
		if (name.length > 40) {
			return name.slice(0, 40) + '...'
		} else {
			return name
		}
	}

	const { enqueueSnackbar } = useSnackbar()

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 550,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
		visibility: backdrop ? 'hidden' : 'visible'
	}

	let iconColor
	if (student.newMig === 'Si') iconColor = 'green'
	if (student.status === 'Inactivo') iconColor = 'red'

	let title
	if (student.newMig === 'Si') title = 'Nuevo migrado'
	if (student.status === 'Inactivo') title = 'Baja'

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<Tooltip
					title={title}
					arrow
				>
					<Typography variant='body2'>{formatName(student.name)}</Typography>
				</Tooltip>
				<Button
					onClick={handleOpen}
					title={title}
					sx={{
						borderRadius: '50%',
						padding: 1.5,
						marginLeft: 1
					}}
				>
					<InfoTwoToneIcon style={{ color: iconColor }} />
				</Button>
			</Box>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Box sx={style}>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<Box sx={{ width: '80%', marginBottom: '1rem' }}>
							<Typography
								id='modal-modal-title'
								fontWeight='bold'
								variant='h5'
								component='h2'
							>
								{student.name}
							</Typography>
						</Box>
					</Box>

					<Divider
						color='darkgray'
						sx={{ marginBottom: '1rem' }}
					/>

					<Box
						sx={{
							// border: '4px solid red',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							minHeight: '15vh'
						}}
					>
						{/* PAÍS */}
						<Box
							sx={{
								// border: '3px dotted blue',
								display: 'flex',
								align: 'center',
								height: 40,
								padding: '0.5rem'
							}}
						>
							{student.country.length ? (
								<>
									<Typography
										id='modal-modal-description'
										sx={{ display: 'flex', align: 'center' }}
									>
										<img
											src={mundoImg}
											width='60'
											alt='Mundo img'
											title='País'
										/>
										<span
											style={{
												fontWeight: 'bold',
												marginTop: 10,
												marginLeft: 2
											}}
										>
											{student.country}
										</span>
									</Typography>
									<Box style={{ marginLeft: 15, marginTop: 10 }}>
										<Flags
											countryCode={student.countryCode}
											countryName={student.country}
											height={20}
										/>
									</Box>
								</>
							) : (
								<IconButton
									color='black'
									aria-label='no disponible'
									disabled
								>
									<BlockRoundedIcon />
								</IconButton>
							)}
						</Box>

						{/* EMAIL */}
						<Box
							sx={{
								// border: '3px dotted blue',
								display: 'flex',
								align: 'center',
								height: 40,
								padding: '0.5rem'
							}}
						>
							<Typography
								id='modal-modal-description'
								sx={{ display: 'flex', align: 'center' }}
							>
								<img
									src={emailLogo}
									// width='30'
									style={{ marginLeft: 10 }}
									alt='Logo Email'
									title='Dirección de Email'
								/>
								<span style={{ fontWeight: 'bold', marginLeft: 12, marginTop: 7 }}>{student.email}</span>
								<ContentCopyIcon
									sx={{
										marginLeft: '0.5rem',
										marginTop: 1,
										'&:hover': {
											color: '#FFC107',
											cursor: 'pointer'
										}
									}}
									onClick={() => {
										navigator.clipboard.writeText(student.email)
										enqueueSnackbar('Email copiado al portapapeles', {
											variant: 'success'
										})
									}}
								/>
							</Typography>
						</Box>

						{/* GITHUB */}
						<Box
							sx={{
								// border: '3px dotted blue',
								display: 'flex',
								align: 'center',
								height: 40,
								padding: '0.5rem'
							}}
						>
							<Typography
								id='modal-modal-description'
								sx={{ display: 'flex', align: 'center' }}
							>
								<img
									src={githubLogo}
									height='35px'
									style={{ marginLeft: 14, marginTop: 2 }}
									alt='Logo Github'
									title='Usuario de Github'
								/>
								<span style={{ fontWeight: 'bold', marginLeft: 12, marginTop: 7 }}>{student.github}</span>
							</Typography>
						</Box>

						{/* SLACK */}
						<Box
							sx={{
								// border: '3px dotted blue',
								display: 'flex',
								align: 'center',
								height: 40,
								padding: '0.5rem'
							}}
						>
							<Typography
								id='modal-modal-description'
								sx={{ display: 'flex', align: 'center' }}
							>
								<img
									src={slackLogo}
									style={{ marginLeft: 10 }}
									alt='Logo Slack'
									title='ID de Slack'
								/>
								<span style={{ fontWeight: 'bold', marginTop: 7, marginLeft: 11 }}>{student.slackId}</span>
								<ContentCopyIcon
									sx={{
										marginLeft: '0.5rem',
										marginTop: '0.5vh',
										'&:hover': {
											color: '#FFC107',
											cursor: 'pointer'
										}
									}}
									onClick={() => {
										navigator.clipboard.writeText(student.slackId)
										enqueueSnackbar('ID de Slack copiado al portapapeles', {
											variant: 'success'
										})
									}}
								/>
							</Typography>
						</Box>

						<Divider
							color='darkgray'
							style={{ marginBottom: '1rem', marginTop: '1rem' }}
						/>
						{student.newMig === 'Si' && (
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginBottom: '1rem'
								}}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'space-evenly'
									}}
								>
									<Alert severity='warning'>
										<strong>{student.name.split(' ')[0]}</strong> es {student.gender === 'Mujer' ? 'una nueva' : 'un nuevo'} estudiante en tu grupo de Sup.
									</Alert>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-evenly',
											alignItems: 'center',
											marginTop: '1rem'
										}}
									>
										<FormControlLabel
											control={
												<Checkbox
													checked={slack}
													onChange={handleSlack}
													name='slack'
													color='primary'
												/>
											}
											label={`Ya ${student.gender === 'Mujer' ? 'la' : 'lo'} agregué al canal de Slack`}
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={meet}
													onChange={handleMeet}
													name='meet'
													color='primary'
												/>
											}
											label={`Ya ${student.gender === 'Mujer' ? 'la' : 'lo'} invité a Meet`}
										/>
									</Box>
								</Box>
							</Box>
						)}

						{slack && meet && (
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginBottom: '1rem'
								}}
							>
								<Button
									variant='contained'
									color='success'
									size='small'
									sx={{
										borderRadius: '5px',
										padding: '0.7rem',
										marginTop: 2
									}}
									onClick={() => handleMig(student)}
								>
									Finalizar ingreso al grupo
								</Button>
							</Box>
						)}

						{student.status === 'Inactivo' && (
							<>
								<Box
									sx={{
										// border: '2px solid red',
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										marginBottom: '1rem'
									}}
								>
									<Alert severity='warning'>
										<strong>{student.name.split(' ')[0]}</strong> ya no pertenece a tu grupo de SUP.
									</Alert>
								</Box>
								<Button
									variant='contained'
									color='error'
									size='small'
									sx={{
										borderRadius: '5px',
										padding: '0.7rem',
										marginTop: 2
									}}
									onClick={() => handleDelete(student)}
								>
									Eliminar de la lista
								</Button>
							</>
						)}
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default StudentInfoView
