import { useState, useEffect, useCallback } from 'react'
import { getStudents, getLessonAttendances, setAttendances, getLessons } from '../../redux/actions'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import Students from './Students'
import Menu from './Menu/Menu'

const Home = () => {
	const { enqueueSnackbar } = useSnackbar()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [backdrop, setBackdrop] = useState(false)
	const [open, setOpen] = useState(false)
	const [attendance, setAttendance] = useState({})

	const students = useSelector((state) => state.students)
	const actualLesson = useSelector((state) => state.actualLesson)
	const user = useSelector((state) => state.user)
	const career = useSelector((state) => state.career)
	const allLessons = useSelector((state) => state.allLessons)
	const lastLesson = useSelector((state) => state.lastLesson)
	const actualModule = useSelector((state) => state.actualModule)

	const allLessonsJoined = allLessons.flat()

	const isAnyStudentEvaluated = students.some((student) => student.score !== 'N/D')

	const disabled =
		career === 'Full Stack'
			? !(allLessonsJoined.indexOf(lastLesson) >= 8 && !isAnyStudentEvaluated)
			: career === 'Data Science'
			? !(allLessonsJoined.indexOf(lastLesson) >= 9 && !isAnyStudentEvaluated)
			: null

	useEffect(() => {
		if (allLessons.length < 1 && user.token?.length) {
			dispatch(getLessons(user, career))
		}
	}, [allLessons.length, dispatch, user, career])

	const postAttendance = (overwrite) => {
		setBackdrop(true)
		dispatch(setAttendances(user.career, user.cohort.replace('#', '%23'), user.group, actualLesson, attendance, overwrite, user.token))
			.then((setAttendancesResponse) => {
				if (setAttendancesResponse.message === 'Esta clase ya registraba asistencias') {
					setOpen(true)
				} else {
					enqueueSnackbar(setAttendancesResponse.message, {
						variant: 'success'
					})
				}
			})
			.then(() => handleGetStudents())
			.then(() => setBackdrop(false))
			.catch((err) => {
				enqueueSnackbar('Error al cargar asistencias', {
					variant: 'error'
				})
				console.error(err)
				setBackdrop(false)
			})
	}

	const handleAttendanceChange = (github, value) => {
		setAttendance({
			...attendance,
			[github]: value === 'present'
		})
	}

	const handleGetLessonAttendance = useCallback(() => {
		dispatch(getLessonAttendances(user, actualLesson))
			.then((getLessonAttendancesResponse) => {
				setAttendance(getLessonAttendancesResponse)
			})
			.catch((err) => {
				enqueueSnackbar('Error al cargar asistencias', {
					variant: 'error'
				})
				console.error(err)
			})
	}, [dispatch, user, actualLesson, enqueueSnackbar])

	const handleGetStudents = useCallback(() => {
		setBackdrop(true)
		dispatch(getStudents(user, actualModule))
			.then(() => navigate('/home'))
			.catch((error) => {
				console.error(error.message)
			})
			.finally(() => setBackdrop(false))
	}, [dispatch, user, navigate, setBackdrop, actualModule])

	const itsPosibleToGet = allLessonsJoined?.indexOf(actualLesson) <= allLessonsJoined?.indexOf(lastLesson)

	useEffect(() => {
		if (actualLesson && actualModule) {
			handleGetLessonAttendance()
		}
		const fetchData = () => {
			if (!user.token) {
				enqueueSnackbar('¡No estás logueado!', {
					variant: 'error',
					onExit: navigate('/')
				})
			} else {
				handleGetStudents()
			}
		}
		fetchData()
	}, [user.token, enqueueSnackbar, navigate, handleGetStudents, actualLesson, actualModule, itsPosibleToGet, handleGetLessonAttendance, students.length])

	return (
		<>
			<Menu
				postAttendance={postAttendance}
				attendance={attendance}
				setAttendance={setAttendance}
				disabled={disabled}
			/>
			<Students
				attendance={attendance}
				backdrop={backdrop}
				setBackdrop={setBackdrop}
				open={open}
				setOpen={setOpen}
				students={students}
				postAttendance={postAttendance}
				handleAttendanceChange={handleAttendanceChange}
			/>
		</>
	)
}

export default Home
