import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import EvaluationView from './Evaluation.view'

const Evaluation = () => {
	const [evaluation, setEvaluation] = useState([])

	const [open, setOpen] = useState(false)

	const students = useSelector((state) => state.students)

	return (
		<EvaluationView
			students={students}
			evaluation={evaluation}
			setEvaluation={setEvaluation}
			setOpen={setOpen}
			open={open}
		/>
	)
}

export default Evaluation
