import React, { useState } from 'react'
import { Grid, Card, CardContent, Typography, Button, Modal, Box, Divider } from '@mui/material'
import { useSnackbar } from 'notistack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import slackLogo from '../../assets/slack.png'
import emailLogo from '../../assets/email-512.png'

const CohortGroupDetail = ({ group }) => {
	const { enqueueSnackbar } = useSnackbar()

	const [openTA, setOpenTA] = useState(false)

	const handleClose = () => setOpenTA(false)

	return (
		<>
			<Modal
				open={openTA}
				onClose={() => setOpenTA(false)}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<Grid
					container
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '50%',
						bgcolor: 'background.paper',
						border: '2px solid #000',
						boxShadow: 24,
						p: 4
					}}
				>
					<Button
						variant='contained'
						color='error'
						onClick={() => handleClose()}
						sx={{
							position: 'absolute',
							top: 0,
							right: 0,
							backgroundColor: '#f44336',
							color: '#fff',
							borderRadius: '0 0 0 5px'
						}}
					>
						X
					</Button>
					<Grid
						item
						xs={12}
					>
						<Typography
							id='modal-modal-title'
							variant='h5'
							component='h2'
							sx={{
								fontWeight: 'bold',
								textAlign: 'center',
								mb: 2
							}}
							color='textSecondary'
						>
							TA's del grupo N°{group.group}
						</Typography>
					</Grid>

					<Divider
						sx={{
							width: '90%',
							marginBottom: '1%',
							marginTop: '1%',
							ml: '5%',
							mr: '5%'
						}}
					/>

					<Grid
						item
						xs={12}
					>
						{group?.TAs?.map((ta, i) => {
							return (
								<Box
									key={i}
									sx={{
										// border: '3px dotted blue',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										align: 'center',
										padding: '0.5rem',
										mt: 4,
										boxShadow: 0.5,
										borderRadius: 2,
										'&:hover': {
											boxShadow: 3,
											cursor: 'default'
										}
									}}
								>
									<Typography
										key={ta.id}
										variant='h6'
										color='textSecondary'
										sx={{ fontWeight: 'bold', mb: 1 }}
									>
										{ta.name + ' ' + ta.lastName}
									</Typography>
									<Typography
										id='modal-modal-description'
										sx={{
											display: 'flex',
											align: 'center',
											fontWeight: 'bold',
											mt: 1
										}}
										color='textSecondary'
									>
										<img
											src={emailLogo}
											width='30'
											style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
											alt='Logo Email'
											title='Dirección de Email'
										/>
										{ta.email}
										<ContentCopyIcon
											sx={{
												marginLeft: '0.5rem',
												'&:hover': {
													color: '#FFC107',
													cursor: 'pointer'
												}
											}}
											onClick={() => {
												navigator.clipboard.writeText(ta.email)
												enqueueSnackbar('Email copiado al portapapeles', {
													variant: 'success'
												})
											}}
										/>
									</Typography>

									<Typography
										id='modal-modal-description'
										sx={{
											display: 'flex',
											align: 'center',
											fontWeight: 'bold',
											mt: 1
										}}
										color='textSecondary'
									>
										<img
											src={slackLogo}
											width='30'
											style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}
											alt='Logo Slack'
											title='ID Slack'
										/>
										{ta.slackId}
										<ContentCopyIcon
											sx={{
												marginLeft: '0.5rem',
												'&:hover': {
													color: '#FFC107',
													cursor: 'pointer'
												}
											}}
											onClick={() => {
												navigator.clipboard.writeText(ta.slackId)
												enqueueSnackbar('ID de Slack copiado al portapapeles', {
													variant: 'success'
												})
											}}
										/>
									</Typography>
								</Box>
							)
						})}
					</Grid>
				</Grid>
			</Modal>

			<Grid
				item
				key={group.sheetID}
				xs={12}
				sm={6}
				md={4}
				sx={{ p: 2 }}
			>
				<Card key={group.sheetID}>
					<CardContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center'
						}}
					>
						<Typography
							variant='h5'
							component='h2'
							gutterBottom
							color='textSecondary'
							sx={{ fontWeight: 'bold' }}
						>
							Grupo N° {group.group}
						</Typography>
						<Typography
							variant='body1'
							color='textSecondary'
						>
							Total de estudiantes: {group.studentCount}
						</Typography>
						<Typography
							variant='body1'
							color='textSecondary'
						>
							Asistencias registradas: {group.totalLessonsWithAttendance}
						</Typography>
						<Typography
							variant='body1'
							color={'textSecondary'}
						>
							Asistencia: {group.attendanceAvg}%
						</Typography>
						<Typography
							variant='body1'
							color='textSecondary'
						>
							Nuevos alumnos: {group.newMigsCount}
						</Typography>
						<Typography
							variant='body1'
							color='textSecondary'
						>
							Alumnos inactivos: {group.inactiveCount}
						</Typography>

						<Button
							variant='contained'
							color='warning'
							sx={{ mt: 2 }}
							onClick={() => setOpenTA(true)}
						>
							Ver TA's
						</Button>
					</CardContent>
				</Card>
			</Grid>
		</>
	)
}

export default CohortGroupDetail
