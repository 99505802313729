import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { logoutUser } from '../../redux/actions'
import { useAuth } from '../../service/Auth'
import NavView from './Nav.view'

const Nav = () => {
  const dispatch = useDispatch()
  const location = useLocation().pathname
  const user = useSelector((state) => state.user)
  const career = useSelector((state) => state.career)
  const { logOut } = useAuth();

  const logout = () => {
    logOut({ returnTo: '/' });
    dispatch(logoutUser())
  }

  return (
    <NavView
      user={user}
      career={career}
      location={location}
      logout={logout}
    />
  )
}

export default Nav
