export const actionTypes = {
  SET_USER: 'SET_USER',
  SET_STUDENTS: 'SET_STUDENTS',
  CLEAR_STUDENTS: 'CLEAR_STUDENTS',
  SET_ATTENDANCE: 'SET_ATTENDANCE',
  GET_LESSON_ATTENDANCE: 'GET_LESSON_ATTENDANCE',
  SET_ALL_LESSONS: 'SET_ALL_LESSONS',
  SET_ACTUAL_MODULE: 'SET_ACTUAL_MODULE',
  SET_ACTUAL_LESSON: 'SET_ACTUAL_LESSON',
  LOGOUT: 'LOGOUT',
  SET_LAST_LESSON: 'SET_LAST_LESSON',
  SET_NEXT_LESSON: 'SET_NEXT_LESSON',
  GET_COHORTS: 'GET_COHORTS',
  GET_COHORT_GROUPS: 'GET_COHORT_GROUPS',
  CREATE_COHORT: 'CREATE_COHORT',
  GET_LESSONS_ADMIN: 'GET_LESSONS_ADMIN',
  UPDATE_STUDENT_MIGRATION: 'UPDATE_STUDENT_MIGRATION',
  UPDATE_STUDENT_GROUP: 'UPDATE_STUDENT_GROUP',
  UPDATE_STUDENT_STATUS: 'UPDATE_STUDENT_STATUS',
  NEW_STUDENT: 'NEW_STUDENT',
  MIGRATIONS: 'MIGRATIONS',
  POST_EVALUATIONS: 'POST_EVALUATIONS',
  SET_CAREER: 'SET_CAREER',
  DELETE_COHORT: 'DELETE_COHORT',
  DELETE_STUDENT: 'DELETE_STUDENT',
  CREATE_TA: 'CREATE_TA',
  DELETE_TA: 'DELETE_TA',
  UPDATE_GROUP_TA: 'UPDATE_GROUP_TA'
}
