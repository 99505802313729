import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
// import rootReducer from './reducer'
import persistReducer from './persistReducer'

const store = createStore(persistReducer, composeWithDevTools(applyMiddleware(thunk)))

const persistor = persistStore(store)
const exports = { store, persistor }

export default exports
