import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { createTA } from '../../../../redux/actions'
import Loading from '../../../commons/Loading'
import { Box, Button, Typography, Modal, TextField, FormControl, Stack } from '@mui/material'

const NewTA = ({ open, handleClose }) => {
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	const token = useSelector((state) => state.user.token)
	const career = useSelector((state) => state.career)

	const [mail, setMail] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handleMail = (event) => {
		setMail(event.target.value)
	}

	const clearInputs = () => {
		setMail('')
	}

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateEmail(mail)) {
			enqueueSnackbar('El mail ingresado no es válido', {
				variant: 'error'
			})
			return
		}
		setIsLoading(true)
		dispatch(createTA(token, mail, career))
			.then((response) => {
				setIsLoading(false)
				enqueueSnackbar(response.message, { variant: 'success' })
				handleClose()
				clearInputs()
			})
			.catch((error) => {
				setIsLoading(false)
				enqueueSnackbar(error.message, {
					variant: 'error'
				})
			})
	}

	const style = {
		// border: '5px solid red',
		display: 'flex',
		justifyContent: 'center',
		border: '2px solid #000',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 580,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}

	useEffect(() => {}, [])

	return (
		<>
			{isLoading && <Loading />}

			{open && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					BackdropProps={{ onClick: () => {} }} // Para que no se cierre al hacer click afuera
				>
					<Box sx={style}>
						<Stack
							sx={{
								// border: '5px solid blue',
								width: '80%',
								minHeight: 300,
								display: 'flex',
								alignItems: 'center',
								margin: 1
							}}
						>
							<Typography
								variant='h5'
								component='h5'
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '1rem'
								}}
							>
								Ingresa el email del nuevo TA
							</Typography>

							{/* Boton de Cerrar */}
							<Button
								variant='contained'
								color='error'
								onClick={() => {
									handleClose()
									clearInputs()
								}}
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
									backgroundColor: '#f44336',
									color: '#fff',
									borderRadius: '0 0 0 5px'
								}}
							>
								X
							</Button>

							<FormControl
								sx={{ m: 1, minWidth: 120 }}
								size='small'
							>
								{/* INPUTS */}
								<Stack
									sx={{
										// border: '5px solid magenta',
										width: 400,
										height: 150,
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'column',
										margin: 1,
										marginTop: '2rem',
										marginBottom: '1rem'
									}}
								>
									{/* EMAIL */}
									<TextField
										sx={{
											// border: '5px solid pink',
											width: '75%',
											margin: 1,
											marginTop: '2rem'
										}}
										id='outlined-basic'
										label='Email'
										variant='standard'
										value={mail}
										onChange={handleMail}
									/>
								</Stack>
							</FormControl>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flexDirection: 'row'
								}}
							>
								<Button
									variant='contained'
									sx={{ width: '40%' }}
									disabled={isLoading}
									onClick={clearInputs}
								>
									Limpiar
								</Button>
								<Button
									variant='contained'
									color='success'
									sx={{ width: '40%' }}
									disabled={isLoading || !career || !mail}
									onClick={handleSubmit}
								>
									Crear
								</Button>
							</Box>
						</Stack>
					</Box>
				</Modal>
			)}
		</>
	)
}

export default NewTA
