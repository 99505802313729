import { actionTypes } from './actionTypes'

const initialState = {
  user: {},
  actualCohort: {},
  career: 'Full Stack',
  cohorts: [],
  students: [],
  actualLessonAttendance: [],
  allLessons: [],
  actualModule: 1,
  actualLesson: '',
  lastLesson: '',
  nextLesson: ''
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case actionTypes.SET_CAREER:
      return {
        ...state,
        career: action.payload
      }
    case actionTypes.SET_STUDENTS:
      return {
        ...state,
        students: action.payload
      }
    case actionTypes.CLEAR_STUDENTS:
      return {
        ...state,
        students: action.payload
      }
    case actionTypes.SET_ATTENDANCE:
      return {
        ...state
      }
    case actionTypes.GET_LESSON_ATTENDANCE:
      return {
        ...state,
        actualLessonAttendance: action.payload
      }

    case actionTypes.SET_ALL_LESSONS:
      return {
        ...state,
        allLessons: action.payload
      }
    case actionTypes.SET_ACTUAL_MODULE:
      return {
        ...state,
        actualModule: action.payload
      }
    case actionTypes.SET_ACTUAL_LESSON:
      return {
        ...state,
        actualLesson: action.payload
      }
    case actionTypes.SET_LAST_LESSON:
      return {
        ...state,
        lastLesson: action.payload
      }

    case actionTypes.SET_NEXT_LESSON:
      return {
        ...state,
        nextLesson: action.payload
      }

    case actionTypes.GET_COHORTS:
      return {
        ...state,
        cohorts: action.payload
      }

    case actionTypes.GET_COHORT_GROUPS:
      return {
        ...state,
        actualCohort: action.payload
      }

    case actionTypes.SET_ACTUAL_STUDENT:
      return {
        ...state,
        actualStudent: action.payload
      }

    case actionTypes.MIGRATIONS:
      return {
        ...state
      }

    case actionTypes.UPDATE_STUDENT_MIGRATION:
      return {
        ...state
      }

    case actionTypes.UPDATE_STUDENT_GROUP:
      return {
        ...state
      }

    case actionTypes.UPDATE_STUDENT_STATUS:
      return {
        ...state
      }

    case actionTypes.CREATE_COHORT:
      return {
        ...state
      }

    case actionTypes.GET_LESSONS_ADMIN:
      return {
        ...state,
        allLessons: action.payload
      }

    case actionTypes.CREATE_TA:
      return {
        ...state
      }

    case actionTypes.DELETE_TA:
      return {
        ...state
      }

    case actionTypes.UPDATE_GROUP_TA:
      return {
        ...state
      }

    case actionTypes.DELETE_COHORT:
      return {
        ...state
      }

    case actionTypes.DELETE_STUDENT: {
      const filteredStudents = state.students.filter((student) => student.github !== action.payload)

      return {
        ...state,
        students: filteredStudents
      }
    }

    case actionTypes.NEW_STUDENT:
      return {
        ...state
      }

    case actionTypes.POST_EVALUATIONS:
      return {
        ...state
      }

    case actionTypes.LOGOUT:
      return (state = initialState)

    default:
      return state
  }
}

export default rootReducer
