import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import ConfirmModal from './ConfirmModal'
import { Box, ThemeProvider, Button, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import theme from '../../theme/theme'
import Loading from '../commons/Loading'
import StudentInfo from '../StudentInfo/StudentInfo'
import Flags from '../commons/Flags'
import AttendanceToogle from './AttendanceToogle'
import NotStudents from './NotStudents'

const Students = ({ attendance, backdrop, setBackdrop, open, setOpen, students, postAttendance, handleAttendanceChange }) => {
	const [openDialog, setOpenDialog] = useState(false)
	const actualModule = useSelector((state) => state.actualModule)

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	let id = 0
	const rows =
		students.length &&
		students.map((student) => ({
			key: student.github,
			id: ++id,
			name: student.name || 'Sin nombre',
			email: student.email || '-',
			github: student.github || '-',
			status: student.status || '-',
			attendanceAvg: student.attendanceAvg ? `${student.attendanceAvg}%`: 'N/A',
			country: student.country || '-',
			countryCode: student.countryCode || '-',
			gender: student.gender || '-',
			slackId: student.slackId || '-',
			group: student.group || '-',
			newMig: student.newMig
		}))

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			sortable: false,
			filterable: false,
			flex: 1.0,
			hide: true
		},

		{
			field: 'name',
			headerName: 'Nombre y Apellido',
			sortable: true,
			filterable: true,
			flex: 1.0,
			headerAlign: 'center',
			align: 'center',
			justifyContent: 'space-around',
			minWidth: 400,
			maxWidth: 500,
			renderCell: (params) => (
				<StudentInfo
					student={params.row}
					setBackdrop={setBackdrop}
					backdrop={backdrop}
				/>
			)
		},

		{
			field: 'country',
			headerName: 'País',
			sortable: true,
			filterable: true,
			flex: 0.2,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => (
				<Flags
					countryCode={params.row.countryCode}
					countryName={params.row.country}
					height={14}
				/>
			)
		},

		{
			field: 'attendanceAvg',
			headerName: `Presentismo M${actualModule}`,
			sortable: false,
			filterable: true,
			flex: 0.2,
			headerAlign: 'center',
			align: 'center'
		},

		{
			field: 'attendance',
			headerName: 'Registrar asistencia',
			sortable: false,
			filterable: false,
			editable: false,
			flex: 0.5,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				const github = params.row.github
				const value = attendance[github] === true ? 'present' : attendance[github] === false ? 'absent' : null

				return params.row.status === 'Activo' ? (
					<AttendanceToogle
						value={value}
						handleAttendanceChange={handleAttendanceChange}
						github={github}
					/>
				) : null
			}
		}
	]

	const newMig = students.find((student) => student.newMig === 'Si')

	useEffect(() => {
		if (newMig) handleOpenDialog()
	}, [students, newMig, setOpen, attendance])

	return (
		<>
			<ConfirmModal
				open={open}
				setOpen={setOpen}
				postAttendance={postAttendance}
			/>

			{students.length ? (
				<ThemeProvider theme={theme}>
					<Box
						sx={{
							height: '73vh',
							width: 'auto',
							padding: 1,
							maxWidth: '95vw',
							margin: 'auto',
							bgcolor: 'background.paper',
							marginTop: '1vh',
							border: '1px solid gray',
							boxShadow: 24,
							borderRadius: 1
						}}>
						<DataGrid
							rows={rows}
							columns={columns}
							pageSize={students.length}
							rowsPerPageOptions={[students.length]}
							disableSelectionOnClick
							experimentalFeatures={{ newEditingApi: true }}
							hideFooter
							disableVirtualization
						/>
					</Box>
					<Dialog
						open={openDialog}
						onClose={handleCloseDialog}
						aria-labelledby='alert-dialog-title'
						aria-describedby='alert-dialog-description'>
						<Alert severity='warning'>
							<DialogTitle id='alert-dialog-title'>¡Atención!</DialogTitle>
							<DialogContent>
								<DialogContentText id='alert-dialog-description'>
									Hay estudiantes nuevos en el grupo! Por favor, invítalos a la meet y agrégalos al canal de Slack.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleCloseDialog}
									autoFocus
									sx={{
										color: 'white',
										backgroundColor: '#f50057',
										'&:hover': {
											backgroundColor: '#f50057',
											opacity: [0.9, 0.8, 0.7]
										}
									}}>
									Entendido
								</Button>
							</DialogActions>
						</Alert>
					</Dialog>
				</ThemeProvider>
			) : (
				<NotStudents />
			)}

			{backdrop ? <Loading /> : null}
		</>
	)
}

export default Students
