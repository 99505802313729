import React, { useCallback } from 'react'
import LessonItem from './LessonItem'
import { Modal, Typography, Box, Divider, Grid, Button, Alert } from '@mui/material'


const Lessons = ({ open, handleClose, disabledLessons, setDisabledLessons, lessonSchedule = [] }) => {
  const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes']
  const [rescheduledLessons, setRescheduledLessons] = React.useState({})
  const [scheduledLessons, setScheduledLessons] = React.useState({})

  const organizeLessonsByDay = useCallback(() => {
    const newScheduledLessons = {}

    lessonSchedule.forEach((moduleItem) => {
      moduleItem.lessons.forEach((lesson, j) => {
        const dayIndex = j + (rescheduledLessons[moduleItem.module]?.[lesson] || 0)

        if (!newScheduledLessons[dayIndex]) {
          newScheduledLessons[dayIndex] = []
        }
        newScheduledLessons[dayIndex] = [
          ...newScheduledLessons[dayIndex],
          {
            module: moduleItem.module,
            lesson
          }
        ]
      })
    })

    setScheduledLessons(newScheduledLessons)
  }, [lessonSchedule, rescheduledLessons])

  React.useEffect(() => {
    organizeLessonsByDay()
  }, [organizeLessonsByDay])

  const handleRescheduleLesson = (module, lesson, direction) => {
    setRescheduledLessons((prevState) => {
      const newRescheduledLessons = JSON.parse(JSON.stringify(prevState)) // Realiza una copia profunda del estado anterior

      if (!newRescheduledLessons[module]) {
        newRescheduledLessons[module] = {}
      }

      const lessons = lessonSchedule.find((item) => item.module === module).lessons
      const lessonIndex = lessons.indexOf(lesson)

      if (direction === 'forward') {
        for (let i = lessonIndex; i < lessons.length; i++) {
          newRescheduledLessons[module][lessons[i]] = (newRescheduledLessons[module][lessons[i]] || 0) + 1
        }
      } else if (direction === 'backward') {
        for (let i = lessonIndex; i < lessons.length; i++) {
          if (newRescheduledLessons[module][lessons[i]] && newRescheduledLessons[module][lessons[i]] > 0) {
            newRescheduledLessons[module][lessons[i]] -= 1
          }
        }
      }

      return newRescheduledLessons
    })

    organizeLessonsByDay()
  }

  const totalLessons = lessonSchedule.reduce((total, currentModule) => {
    return total + currentModule.lessons.length
  }, 0)

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      BackdropProps={{ onClick: () => {} }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          minWidth: '80vw',
          maxWidth: '90vw',
          maxHeight: '90vh',
          overflow: 'auto',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            bgcolor: 'background.paper'
          }}
        >
          {/* Boton de Cerrar */}
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              handleClose()
            }}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              backgroundColor: '#f44336',
              color: '#fff',
              borderRadius: '0 0 0 5px'
            }}
          >
            X
          </Button>

          <Typography
            variant='h5'
            component='h2'
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              pt: 4,
              pb: 2
            }}
          >
            Clases Activas
          </Typography>

          <Divider />
        </Box>

        <Box>
          <Alert
            severity='info'
            sx={{ m: 2 }}
          >
            <Typography
              variant='body2'
              component='p'
            >
              <Typography
                component='span'
                fontWeight='bold'
              >
                Atención:
              </Typography>{' '}
              Al guardar los cambios, se deshabilitarán las clases que no hayan sido seleccionadas.
            </Typography>
          </Alert>
        </Box>

        <Box
          sx={{
            maxWidth: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            p: 2
          }}
        >
          <Grid
            container
            spacing={2}
          >
            {/* Encabezados de los días */}
            <Grid
              item
              xs={2}
              sx={{ marginBottom: '3rem' }}
            />
            {daysOfWeek.map((day, index) => (
              <Grid
                key={index}
                item
                xs={2}
              >
                <Typography
                  variant='h6'
                  component='h3'
                  align='center'
                >
                  {day}
                </Typography>
              </Grid>
            ))}

            <Grid container>
              {/* Módulos y lecciones */}
              {lessonSchedule.map((moduleItem, i) => (
                <React.Fragment key={i}>
                  <Grid container>
                    <Grid
                      item
                      xs={2}
                    >
                      <Typography
                        variant='h5'
                        component='h3'
                        align='center'
                      >
                        {moduleItem.module}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={10}
                      spacing={2}
                    >
                      {Object.entries(scheduledLessons).map(([dayIndex, lessons]) => {
                        if (lessons.some((l) => l.module === moduleItem.module)) {
                          return lessons
                            .filter((l) => l.module === moduleItem.module)
                            .map((lessonObj, index) => (
                              <Grid
                                key={`${lessonObj.module}_${lessonObj.lesson}_${index}`}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                lg={2.4}
                              >
                                <LessonItem
                                  lesson={lessonObj.lesson}
                                  day={parseInt(dayIndex) + 1}
                                  disabledLessons={disabledLessons}
                                  setDisabledLessons={setDisabledLessons}
                                  onReschedule={handleRescheduleLesson}
                                  module={moduleItem.module}
                                />
                              </Grid>
                            ))
                        } else {
                          return (
                            <Grid
                              key={`empty_${moduleItem.module}_${dayIndex}`}
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={2.4}
                            >
                              {/* Espacio vacío para alinear las lecciones */}
                            </Grid>
                          )
                        }
                      })}
                    </Grid>
                  </Grid>
                  {i < lessonSchedule.length - 1 && (
                  // Evita colocar un Divider después del último módulo
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider sx={{ marginBottom: '1rem', marginTop: '1rem' }} />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mt: 2,
              gap: 2
            }}
          >
            <Alert
              severity='info'
              sx={{
                m: 2
              }}
            >
              <Typography
                variant='body2'
                component='p'
              >
                <Typography
                  component='span'
                  fontWeight='bold'
                >
                  Total de clases seleccionadas: {totalLessons - disabledLessons.length}
                </Typography>
              </Typography>
            </Alert>
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                handleClose()
              }}
              sx={{
                backgroundColor: '#f44336',
                color: '#fff'
              }}
            >
              Cancelar
            </Button>

            <Button
              variant='contained'
              color='warning'
              onClick={() => {
                setDisabledLessons([])
              }}
              sx={{
                backgroundColor: '#ff9800',
                color: '#fff'
              }}
            >
              Limpiar
            </Button>

            <Button
              variant='contained'
              color='success'
              onClick={() => {
                handleClose()
              }}
              sx={{
                backgroundColor: '#4caf50',
                color: '#fff'
              }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default Lessons
