import React from 'react'
import { ThemeProvider, Backdrop, CircularProgress } from '@mui/material'
import theme from '../../theme/theme'

const Loading = () => {
	return (
		<ThemeProvider theme={theme}>
			<Backdrop
				sx={{ color: '#fff', zIndex: 9999 }}
				open
			>
				<CircularProgress color='inherit' />
			</Backdrop>
		</ThemeProvider>
	)
}

export default Loading
