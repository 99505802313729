import { IconButton } from '@mui/material'
import BlockRoundedIcon from '@mui/icons-material/BlockRounded'

const Flags = ({ countryCode, countryName, height }) => {
	return (
		<>
			{countryCode ? (
				<img
					alt={countryName}
					title={countryName}
					height={height}
					src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
				/>
			) : (
				<IconButton
					color='black'
					aria-label='no disponible'
					disabled
				>
					<BlockRoundedIcon />
				</IconButton>
			)}
		</>
	)
}

export default Flags
