import React from 'react'
import { Box, Modal, Alert, AlertTitle, Button, Typography, Divider } from '@mui/material'

const ActionsAlert = ({ open, handleClose, content, severity, handleSecondOpen }) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '60vw',
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24
				}}
			>
				<Button
					variant='contained'
					color='error'
					onClick={() => {
						handleClose()
						handleSecondOpen()
					}}
					sx={{
						position: 'absolute',
						top: 0,
						right: 0,
						backgroundColor: '#f44336',
						color: '#fff',
						borderRadius: '0 0 0 5px'
					}}
				>
					X
				</Button>

				<Alert
					severity={severity.length ? severity : 'info'}
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						gap: 1,
						width: '100%',
						height: '100%',
						p: 2
					}}
				>
					<AlertTitle
						sx={{
							fontSize: '1.2rem',
							fontWeight: 'bold',
							textAlign: 'center',
							mb: 5
						}}
					>
						ATENCIÓN
					</AlertTitle>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							gap: 1
							//   border: '1px solid #ccc',
						}}
					>
						<Typography variant='body1'>
							<strong>{`👉 Migrados exitosamente:`}</strong>
							{` ${content['Migrados exitosamente']}`}
						</Typography>

						<Divider sx={{ width: '100%', my: 1 }} />

						{content['No migrados por no haber sido encontrados en cohorte de origen']?.length > 0 && (
							<>
								<Typography>
									{/* //agrego salto de linea al final de origen: */}
									<strong>{`👉 No migrados por no haber sido encontrados en cohorte de origen:`}</strong>
									<ul>
										{content['No migrados por no haber sido encontrados en cohorte de origen'].map((student, key) => (
											<li key={key}>{student}</li>
										))}
									</ul>
								</Typography>
								<Divider sx={{ width: '100%', my: 1 }} />
							</>
						)}

						{content['No migrados porque ya estaban en la cohorte de destino']?.length > 0 && (
							<>
								<Typography
									variant='body1'
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										gap: 1
									}}
								>
									<strong>{`👉 No migrados porque ya estaban en la cohorte de destino:`}</strong>
									<ul>
										{content['No migrados porque ya estaban en la cohorte de destino'].map((student, key) => (
											<li key={key}>{student}</li>
										))}
									</ul>
								</Typography>

								<Divider sx={{ width: '100%', my: 1 }} />
							</>
						)}

						{content['No pudieron actualizarse en Airtable']?.length > 0 && (
							<>
								<Typography
									variant='body1'
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
										gap: 1
									}}
								>
									<strong>{`👉 No pudieron actualizarse en Airtable:`}</strong>
									<ul>
										{content['No pudieron actualizarse en Airtable'].map((student, key) => (
											<li key={key}>{student}</li>
										))}
									</ul>
								</Typography>
							</>
						)}
					</Box>
				</Alert>
			</Box>
		</Modal>
	)
}

export default ActionsAlert
