import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
  } from 'react';
  import jwtDecode from 'jwt-decode';

  
  const STORAGE_KEY = 'auth';
  
  const Context = createContext(undefined);
  
  export function AuthProvider({
    children,
    authUrl,
    redirectUri,
  }) {
    const [state, setState] = useState({
      isLoading: true,
      isAuthenticated: false,
      accessTokenExpiration: null,
      accessToken: null,
      idToken: null,
      user: null,
    });
  
    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
  
      let authInfo = {
        accessToken: searchParams.get('access_token'),
        idToken: searchParams.get('id_token'),
      };
  
      if (authInfo.accessToken && authInfo.idToken) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(authInfo));
        searchParams.delete('access_token');
        searchParams.delete('id_token');
        let url = `${window.location.origin}${window.location.pathname}`;
        const search = searchParams.toString();
        if (search) {
          url = `${url}?${search}`;
        }
        window.location.replace(url);
      } else {
        const sotrageItem = localStorage.getItem(STORAGE_KEY);
        if (sotrageItem) {
          authInfo = JSON.parse(sotrageItem);
        }
      }
  
      const decodedAccessToken = authInfo.accessToken
        ? jwtDecode(authInfo.accessToken)
        : null;
  
      const accessTokenExpiration =
        decodedAccessToken && new Date(decodedAccessToken.exp * 1000);
  
      if (
        !authInfo.accessToken ||
        !authInfo.idToken ||
        !accessTokenExpiration ||
        new Date() > accessTokenExpiration
      ) {
        setState({
          isLoading: false,
          isAuthenticated: false,
          accessTokenExpiration: null,
          accessToken: null,
          idToken: null,
          user: null,
        });
      } else {
        setState({
          isLoading: false,
          isAuthenticated: true,
          accessTokenExpiration: accessTokenExpiration,
          accessToken: authInfo.accessToken,
          idToken: authInfo.idToken,
          user: jwtDecode(authInfo.idToken),
        });
      }
    }, []);
  
    const logIn = useCallback(
      (logInOptions) => {
        const loginUrl = new URL('/login/', authUrl);
        loginUrl.searchParams.append(
          'redirect_uri',
          logInOptions?.redirectUri || redirectUri || window.location.href
        );
        window.location.replace(loginUrl.toString());
      },
      [redirectUri, authUrl]
    );
  
    const logOut = useCallback(() => {
      localStorage.removeItem(STORAGE_KEY);
      setState({
        isLoading: false,
        isAuthenticated: false,
        accessTokenExpiration: null,
        accessToken: null,
        idToken: null,
        user: null,
      });
    }, [setState]);
  
    const getAccessToken = useCallback(() => {
      if (
        !state.accessToken ||
        !state.accessTokenExpiration ||
        new Date() > state.accessTokenExpiration
      ) {
        return Promise.resolve(null);
      }
      return Promise.resolve(state.accessToken);
    }, [state]);
  
    return (
      <Context.Provider
        value={{
          isLoading: state.isLoading,
          isAuthenticated: state.isAuthenticated,
          user: state.user,
          logIn,
          logOut,
          getAccessToken,
        }}
      >
        {children}
      </Context.Provider>
    );
  }
  
  export const useAuth = () => {
    const context = useContext(Context);
    if (!context) {
      throw new Error('useAuth has to be inside a AuthProvider');
    }
    return context;
  };
  