import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { useSelector } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction='up'
			ref={ref}
			{...props}
		/>
	)
})

export default function AlertDialogSlide({ open, setOpen, postAttendance }) {
	const actualLesson = useSelector((state) => state.actualLesson)

	const handleConfirm = () => {
		postAttendance(true)
		setOpen(false)
	}

	const handleCancel = () => {
		setOpen(false)
	}

	return (
		<div>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => setOpen(false)}
				aria-describedby='alert-dialog-slide-description'
			>
				<DialogTitle>La clase {actualLesson} ya tiene asistencias registradas</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-slide-description'>Deseas sobreescribir todas las asistencias de esta clase?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel}>Cancelar</Button>
					<Button onClick={handleConfirm}>Confirmar</Button>
				</DialogActions>
			</Dialog>
		</div>
	)
}
