import React from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Grid } from '@mui/material'
import CohortGroupDetail from './CohortGroupDetail'

const CohortGroupsInfo = () => {
	const actualCohort = useSelector((state) => state.actualCohort)
	const groups = actualCohort.groups

	useEffect(() => {}, [groups])

	return (
		<Grid container>
			{groups?.length &&
				groups?.map((group, i) => (
					<CohortGroupDetail
						key={i}
						group={group}
					/>
				))}
		</Grid>
	)
}

export default CohortGroupsInfo
