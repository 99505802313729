import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { updateGroupTA } from '../../../../redux/actions'
import Loading from '../../../commons/Loading'
import { Box, Button, Typography, Modal, TextField, FormControl, Stack } from '@mui/material'

const ChangeGroupTA = ({ open, handleClose }) => {
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useDispatch()

	const token = useSelector((state) => state.user.token)

	const [mail, setMail] = useState('')
	const [group, setGroup] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const handleMail = (event) => {
		setMail(event.target.value)
	}

	const handleGroup = (event) => {
		setGroup(event.target.value)
	}

	const clearInputs = () => {
		setMail('')
		setGroup('')
	}

	const validateEmail = (email) => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!validateEmail(mail)) {
			enqueueSnackbar('El mail ingresado no es válido', {
				variant: 'error'
			})
			return
		}
		setIsLoading(true)
		dispatch(updateGroupTA(token, mail, group))
			.then((response) => {
				setIsLoading(false)
				enqueueSnackbar(response.message, { variant: 'success' })
				clearInputs()
			})
			.catch((error) => {
				setIsLoading(false)
				enqueueSnackbar(error.message, {
					variant: 'error'
				})
			})
	}

	const style = {
		// border: '5px solid red',
		display: 'flex',
		justifyContent: 'center',
		border: '2px solid #000',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 580,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4
	}

	useEffect(() => {}, [])

	return (
		<>
			{isLoading && <Loading />}

			{open && (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					BackdropProps={{ onClick: () => {} }} // Para que no se cierre al hacer click afuera
				>
					<Box sx={style}>
						<Stack
							sx={{
								// border: '5px solid blue',
								width: '80%',
								minHeight: 300,
								display: 'flex',
								alignItems: 'center',
								margin: 1
							}}
						>
							<Typography
								variant='h5'
								component='h5'
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'center',
									alignItems: 'center',
									textAlign: 'center',
									gap: '1rem'
								}}
							>
								Ingresa el username (email) del TA y el grupo al que deseas cambiar
							</Typography>

							{/* Boton de Cerrar */}
							<Button
								variant='contained'
								color='error'
								onClick={() => {
									handleClose()
									clearInputs()
								}}
								sx={{
									position: 'absolute',
									top: 0,
									right: 0,
									backgroundColor: '#f44336',
									color: '#fff',
									borderRadius: '0 0 0 5px'
								}}
							>
								X
							</Button>

							<FormControl
								sx={{ m: 1, minWidth: 120 }}
								size='small'
							>
								{/* INPUTS */}
								<Box
									sx={{
										// border: '5px solid magenta',
										width: 400,
										height: 150,
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'row',
										justifyContent: 'space-between',
										margin: 1,
										marginTop: '2rem',
										marginBottom: '1rem',
										gap: '1.5rem'
									}}
								>
									{/* EMAIL */}
									<TextField
										sx={{
											// border: '5px solid pink',
											width: '75%'
										}}
										id='outlined-basic'
										label='Email'
										variant='standard'
										value={mail}
										onChange={handleMail}
									/>

									{/* GROUP */}
									<TextField
										// mismo formato que el de arriba
										id='outlined-basic'
										variant='standard'
										label='Grupo'
										rows={1}
										value={group}
										onChange={handleGroup}
										type='number'
										// no permito que escriban letras
										onKeyPress={(e) => {
											if (!/[0-9]/.test(e.key)) {
												e.preventDefault()
											}
										}}
										// no permito que escriban mas de 2 numeros
										onInput={(e) => {
											if (e.target.value?.length > 2) {
												e.target.value = e.target.value.slice(0, 2)
											}
										}}
										sx={{ width: '30%' }}
									/>
								</Box>
							</FormControl>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flexDirection: 'row'
								}}
							>
								<Button
									variant='contained'
									sx={{ width: '40%' }}
									disabled={isLoading}
									onClick={clearInputs}
								>
									Limpiar
								</Button>
								<Button
									variant='contained'
									color='success'
									sx={{ width: '40%' }}
									disabled={isLoading || !mail.length || !group}
									onClick={handleSubmit}
								>
									Cambiar de grupo
								</Button>
							</Box>
						</Stack>
					</Box>
				</Modal>
			)}
		</>
	)
}

export default ChangeGroupTA
