import React from 'react'
import bgLanding from '../../assets/bgLanding.jpg'
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, OutlinedInput, FormControl, ThemeProvider } from '@mui/material'
import Loading from '../commons/Loading'
import theme from '../../theme/theme'
import { LoginButton } from '../../service/Auth/AuthButtons'

const LoginView = ({ open, handleSubmit, handleChange, handleClickOpen, handleClose, ingresarDisabled, siguienteDisabled, user, backdrop }) => {
	return (
		<>
			{backdrop ? (
				<Loading />
			) : (
				<div
					style={{
						backgroundImage: `url(${bgLanding})`,
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundAttachment: 'fixed',
						backgroundSize: 'cover'
					}}
				>
					<ThemeProvider theme={theme}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100vh'
							}}
						>
							<LoginButton />
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column'
							}}
						>
							<Dialog
								open={open}
								onClose={handleClose}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										handleSubmit(e)
									}
								}}
							>
								<DialogTitle sx={{ textAlign: 'center' }}>Login</DialogTitle>
								<DialogContent>
									<Box
										component='form'
										sx={{ display: 'flex', flexWrap: 'wrap' }}
									>
										<FormControl sx={{ m: 1, minWidth: 200 }}>
											<InputLabel id='demo-dialog-select-label'>Usuario</InputLabel>
											<OutlinedInput
												type='text'
												value={user.userName}
												onChange={handleChange}
												label='Usuario'
												name='userName'
											/>
										</FormControl>
										{/* outlined label para contraseña */}
										<FormControl sx={{ m: 1, minWidth: 200 }}>
											<InputLabel htmlFor='outlined-adornment-password'>Contraseña</InputLabel>
											<OutlinedInput
												type='password'
												value={user.password}
												onChange={handleChange}
												label='Contraseña'
												name='password'
											/>
										</FormControl>
									</Box>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleClose}>Cancelar</Button>
									<Button
										onClick={handleSubmit}
										disabled={siguienteDisabled}
									>
										Ingresar
									</Button>
								</DialogActions>
							</Dialog>
						</Box>
					</ThemeProvider>
				</div>
			)}
		</>
	)
}

export default LoginView
