import React from 'react'
import { Card, CardContent, TextField, Box, Typography, Rating, Tooltip, Button } from '@mui/material'
import ConfirmModal from './ConfirmModal'

const EvaluationView = ({ students, evaluation, setEvaluation, setOpen, open }) => {
	let count = 0
	for (const prop in evaluation) {
		if (evaluation[prop].score && evaluation[prop].comment) {
			count += 2
		}
	}

	return (
		<>
			<Box
				display='flex'
				flexWrap='wrap'
				justifyContent='space-around'
			>
				{students.length ? (
					students.map((student, i) => (
						<Card
							key={i}
							sx={{
								width: '30vw',
								height: 'auto',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								boxShadow: 3,
								margin: '1vw',
								paddingTop: '1vw'
							}}
						>
							<CardContent
								sx={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-around',
									alignItems: 'center',
									width: '100%',
									height: '100%'
								}}
							>
								<Tooltip
									title={student.name}
									disableInteractive
									arrow
								>
									<Typography
										variant='h6'
										component='h2'
									>
										{student.name}
									</Typography>
								</Tooltip>

								<TextField
									label='Comentario'
									multiline
									rows={4}
									value={evaluation.find((e) => e.github === student.github)?.comment || ''}
									variant='outlined'
									fullWidth
									onChange={(e) => {
										const st = evaluation.find((e) => e.github === student.github)
										if (st) {
											setEvaluation([
												...evaluation.filter((e) => e.github !== student.github),
												{
													github: student.github,
													comment: e.target.value,
													score: st.score
												}
											])
										} else {
											setEvaluation([
												...evaluation,
												{
													github: student.github,
													comment: e.target.value,
													score: 0
												}
											])
										}
									}}
								/>
								<Rating
									name='simple-controlled'
									value={evaluation.find((e) => e.github === student.github)?.score || 0}
									onChange={(e, newValue) => {
										const st = evaluation.find((e) => e.github === student.github)
										if (st) {
											setEvaluation([
												...evaluation.filter((e) => e.github !== student.github),
												{
													github: student.github,
													comment: st.comment,
													score: newValue
												}
											])
										} else {
											setEvaluation([
												...evaluation,
												{
													github: student.github,
													comment: '',
													score: newValue
												}
											])
										}
									}}
								/>
							</CardContent>
						</Card>
					))
				) : (
					<Typography
						variant='h5'
						component='h2'
					>
						No hay estudiantes en esta cohorte
					</Typography>
				)}

				<Button
					variant='contained'
					sx={{
						backgroundColor: 'yellow',
						color: 'black',
						borderRadius: '5px',
						'&:hover': {
							backgroundColor: 'yellow',
							opacity: [0.9, 0.8, 0.7],
							color: 'black'
						},
						width: '97%',
						height: 'auto',
						margin: 1,
						marginBottom: 2
					}}
					disabled={count !== students.length * 2}
					onClick={() => setOpen(true)}
				>
					<Typography
						variant='h5'
						component='h2'
					>
						Enviar Evaluación
					</Typography>
				</Button>
			</Box>
			<ConfirmModal
				open={open}
				setOpen={setOpen}
				evaluation={evaluation}
				setEvaluation={setEvaluation}
			/>
		</>
	)
}

export default EvaluationView
