import React from 'react'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Grid, Box, Typography, Paper, IconButton } from '@mui/material'
import sheetIcon from '../../assets/sheet_icon.png'
import Menu from './Menu/Menu'

const CohortInfo = () => {
	const user = useSelector((state) => state.user)
	const career = user.career
	const cohort = user.cohort
	const totalGroups = useSelector((state) => state.actualCohort.groupsCount)
	const attendanceAvg = useSelector((state) => state.actualCohort.attendanceAvg)
	const newMigrations = useSelector((state) => state.actualCohort.newMigsCount)
	const inactiveStudents = useSelector((state) => state.actualCohort.inactiveCount)
	const sheetId = useSelector((state) => state.actualCohort.sheetID)
	const groups = useSelector((state) => state.actualCohort.groups)

	const totalStudents = groups?.map((group) => group.studentCount).reduce((a, b) => a + b, 0)

	const careerUrl =
		career.split('-')[1] === 'fs' ? 'https://docs.google.com/spreadsheets/d/1PRiiBuhSuJQrn4IQeQdduo4nQsxmZWBYXwl3yzqCeus/edit#gid=' : 'https://docs.google.com/spreadsheets/d/1VCzDJK3XgKmT4I1nn5tLXsughNJH3__U68mBBqcLEgo/edit#gid='

	const navigate = (url) => window.open(url, '_blank')

	useEffect(() => {}, [groups])

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center'
				}}
			>
				<Paper
					sx={{
						width: '100%',
						m: 2,
						backgroundColor: 'rgba(255, 255, 255, 0.8)',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center'
					}}
				>
					<Grid
						item
						xs={12}
						md={4}
					>
						<Box
							sx={{
								width: '100%',
								m: 2,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center'
							}}
						>
							<Typography
								variant='h4'
								sx={{ fontWeight: 'bold', mt: 1 }}
								color='textSecondary'
							>
								{cohort.replace('#', '')}
							</Typography>

							<IconButton onClick={() => navigate(careerUrl + sheetId)}>
								<img
									src={sheetIcon}
									alt='sheet icon'
									height='40rem'
								/>
							</IconButton>

							<Typography
								variant='h6'
								sx={{ fontWeight: 'bold' }}
								color='textSecondary'
							>
								Grupos totales: {totalGroups}
							</Typography>

							<Typography
								variant='h6'
								sx={{ fontWeight: 'bold' }}
								color='textSecondary'
							>
								Asistencia: {attendanceAvg}%
							</Typography>
						</Box>
					</Grid>

					<Grid
						item
						xs={12}
						md={4}
					>
						<Box
							sx={{
								width: '100%',
								gap: 2.25,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center'
							}}
						>
							<Typography
								variant='h5'
								sx={{ fontWeight: 'bold' }}
								color='textSecondary'
							>
								Estudiantes
							</Typography>

							<Typography
								variant='body1'
								align='center'
								color='textSecondary'
							>
								Total de estudiantes: {totalStudents}
							</Typography>

							<Typography
								variant='body1'
								align='center'
								color='textSecondary'
							>
								Nuevos alumnos: {newMigrations}
							</Typography>

							<Typography
								variant='body1'
								align='center'
								color='textSecondary'
							>
								Alumnos inactivos: {inactiveStudents}
							</Typography>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
					>
						<Box
							sx={{
								width: '100%',
								gap: 2.25,
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center'
							}}
						>
							<Menu userCareer={career} />
						</Box>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	)
}

export default CohortInfo
