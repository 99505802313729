import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { login, clearStudents, getStudents } from '../../redux/actions'
import LoginView from './Login.view'

const Login = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { enqueueSnackbar } = useSnackbar()

	const userLogged = useSelector((state) => state.user)
	const token = useSelector((state) => state.user.token)
	const actualModule = useSelector((state) => state.actualModule)

	const [backdrop, setBackdrop] = useState(false)
	const [open, setOpen] = useState(false)
	const [user, setUser] = useState({
		userName: '',
		password: ''
	})

	const [ingresarDisabled, setIngresarDisabled] = useState(false)
	const [siguienteDisabled, setSiguienteDisabled] = useState(false)

	const handleClose = (e, reason) => {
		setIngresarDisabled(false)
		if (reason !== 'backdropClick') setOpen(false)
	}

	const handleClickOpen = () => {
		setIngresarDisabled(true)
		dispatch(clearStudents())
		setOpen(true)
	}

	const handleChange = (e) => {
		setUser({
			...user,
			[e.target.name]: e.target.value
		})
	}

	const adminsCareers = useMemo(() => ['super admin', 'admin', 'HA-fs', 'HA-ds'], [])

	const handleLogin = async (e) => {
		e.preventDefault()
		setBackdrop(true)
		setSiguienteDisabled(true)
		dispatch(login(user))
			.then((loggedInUser) => {
				enqueueSnackbar(`¡Bienvenid@ ${loggedInUser.name}!`, {
					variant: 'success',
					onExit: adminsCareers.includes(loggedInUser.career) ? navigate('/dashboard') : navigate('/home')
				})
				setBackdrop(false)
			})
			.catch((error) => {
				setBackdrop(false)
				setIngresarDisabled(false)
				setSiguienteDisabled(false)
				console.error(error.message)
				enqueueSnackbar(error.message, {
					variant: 'error',
					onExit: navigate('/')
				})
			})
	}

	useEffect(() => {
		if (token?.length > 3 && !adminsCareers.includes(userLogged.career)) {
			dispatch(getStudents(userLogged, actualModule)).then(() => {
				enqueueSnackbar(`¡Bienvenid@ ${userLogged.name}!`, {
					variant: 'success',
					onExit: navigate('/home')
				})
			})
		}
	}, [dispatch, enqueueSnackbar, navigate, userLogged, token?.length, adminsCareers, actualModule])

	return (
		<LoginView
			open={open}
			handleSubmit={handleLogin}
			handleChange={handleChange}
			handleClickOpen={handleClickOpen}
			handleClose={handleClose}
			ingresarDisabled={ingresarDisabled}
			siguienteDisabled={siguienteDisabled}
			user={user}
			backdrop={backdrop}
		/>
	)
}

export default Login
