import React from 'react'
import { Box, Typography, FormControlLabel, Switch, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

const LessonItem = ({ lesson, day, disabledLessons, setDisabledLessons, onReschedule, module }) => {
	const handleToggle = () => {
		if (disabledLessons.includes(lesson)) {
			setDisabledLessons(disabledLessons.filter((l) => l !== lesson))
		} else {
			setDisabledLessons([...disabledLessons, lesson])
		}
	}

	const handleForwardClick = () => {
		// console.log('adelante')
		onReschedule(module, lesson, 'forward')
	}

	const handleBackwardClick = () => {
		onReschedule(module, lesson, 'backward')
	}

	const isChecked = disabledLessons.includes(lesson)

	return (
		<Box
			sx={{
				// border: '1px solid red',
				display: 'flex',
				backgroundColor: isChecked ? '#85929E' : '#F2F4F4',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				width: '85%',
				height: '60px',
				boxShadow: 1,
				borderRadius: 1,
				pl: 1,
				pr: 1,
				transition: 'box-shadow 0.3s ease-in-out',
				'&:hover': {
					boxShadow: 3
				}
			}}
		>
			<Stack>
				<Box
					sx={{ 
						width: '200px',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}>

				<Box
			>
					<Typography
						variant='body1'
						component='span'
					>
						{lesson}
					</Typography>
				</Box>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<FormControlLabel
						control={
							<Switch
								checked={!isChecked}
								onChange={handleToggle}
								sx={{
									color: '#f44336',
									'&.Mui-checked': {
										color: '#f44336'
									},
									'&.Mui-checked + .MuiSwitch-track': {
										backgroundColor: '#f44336'
									}
								}}
							/>
						}
					/>
					<Stack direction='row'>
						<IconButton
							color='primary'
							aria-label='Mover al día anterior'
							onClick={handleBackwardClick}
						>
							<ArrowBackIosIcon />
						</IconButton>
						<IconButton
							color='primary'
							aria-label='Mover al día siguiente'
							onClick={handleForwardClick}
						>
							<ArrowForwardIosIcon />
						</IconButton>
					</Stack>
				</Box>
				</Box>

			</Stack>
			
		</Box>
	)
}

export default LessonItem
